import styled from 'styled-components';

export const ContainerInput = styled.div`
    .flag-container {
        width: fit-content !important;
        position: relative !important;
        padding: 0;
    }
    .selected-flag {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;

        ${({ viewMode, theme }) => viewMode ? `
            border: none;
            background-color: transparent;
        ` : `
            background-color: ${theme.darkPrimaryColor} !important;
            border: 2px solid ${theme.darkHighlightColor};
        `}
    }
    .country-list  {
        background-color: ${({ theme }) => theme.darkPrimaryColor} !important;
        border: none;
        border-radius: 8px;
    }
    .country-list .country {
        background-color: ${({ theme }) => theme.darkPrimaryColor} !important;
    }
    .country.highlight-preferred {
        background-color: ${({ theme }) => theme.darkSecondaryColor} !important;
    }
    .country-list .country:hover {
        background-color: ${({ theme }) => theme.darkHighlightColor} !important;
    }
    .allow-dropdown, .intl-tel-input {
        width: 100%;
        display: flex;
    }
    .intl-tel-input.allow-dropdown input {
        padding-left: 14px;
    }
    .intl-tel-input input {
        color: ${({ theme }) => theme.textColor} !important;
        width: 100% !important;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-left: 0 !important;
        ${({ viewMode, theme }) => viewMode ? `
            border: none;
            background-color: transparent;
        ` : `
            padding: 14px;
            background-color: ${theme.darkPrimaryColor} !important;
            border: 2px solid ${theme.darkHighlightColor};
        `}
    }
    .intl-tel-input input:focus {
        outline: none;
    }
    input::placeholder {
        opacity: 50%;
        color: ${({ theme }) => theme.textColor};
    }
    .divider {
        border-bottom: 1px solid ${({ theme }) => theme.darkSecondaryColor} !important;
    }
`;