import { get, post, put } from '../api';
import { mountOptionsCascader, InfoUsuario } from '@utils';
import { queryParamsChamados, renderHTMLTagUser } from './rules';
import omit from 'omit.js';

export const getCategorias = (validas = true, mount = mountOptionsCascader, prazo = false, somenteArvore = true) => {
  return get(`/api/categorias/v${InfoUsuario.get('cdUsuario')}?validas=${validas}&prazo=${prazo}&somenteArvore=${somenteArvore}&idUsuario=${InfoUsuario.get('cdUsuario')}`).then((res) => {
    if (!mount) return res;
    return mount(res, 'id', 'idCategoria', 'titulo');
  });
};

export const getCategoryById = (categoryId) => {
  return get(`/api/categorias/${categoryId}?prazo=false`);
};

export const getChamados = (idUsuario, filtros, limit, search, orderBy) => {
  return get(`/api/chamados/list/${idUsuario}${queryParamsChamados(filtros, limit, search, orderBy)}`);
};

export const getChamado = (id) => {
  return get(`/api/chamados/${id}`);
};

export const updateReadStatus = (id) => {
  return put(`/api/chamados/historic/messages/read/${id}/${InfoUsuario.get('cdUsuario')}`);
};

export const saveChamado = (chamado) => {
  return post('/api/chamados/novo', {
    idCategoria: parseInt(chamado.categoria.slice(-1)[0]),
    idUsuarioPai: InfoUsuario.get('cdUsuario'),
    idUsuarioCriacao: chamado.idUsuarioCriacao || InfoUsuario.get('cdUsuario'),
    idUsuarioExecutar: chamado.idUsuarioExecutar,
    idUnidade: chamado.unidade,
    assunto: chamado.assunto,
    mensagem: chamado.mensagem,
    anexos: chamado.anexos ? chamado.anexos.map((anexo) => anexo.response) : [],
    idChamadoPai: chamado.idChamadoPai,
    bloqueiaChamadoPai: chamado.bloqueiaChamadoPai,
  });
};

export const sendMessage = (message) => {
  return post('/api/chamados/historico', message);
};

export const deleteMessage = (id) => {
  return put('/api/chamados/historic/message/status', { id, active: false });
};

export const changeCategoria = (idChamado, categoria) => {
  const idCategoria = parseInt(categoria.slice(-1)[0]);

  return post(`/api/chamados/${idChamado}/categoria/${idCategoria}`, {
    idUsuario: InfoUsuario.get('cdUsuario'),
  });
};

export const changeStatus = (idChamado, status) => {
  return post(`/api/chamados/${idChamado}/status/${status}`, {
    idUsuario: InfoUsuario.get('cdUsuario'),
  });
};

export const changeAguardandoResposta = (idChamado, aguardandoResposta) => {
  return post(`/api/chamados/${idChamado}/aguardando_resposta`, aguardandoResposta);
};

export const changeResponsavel = (idChamado, novoResponsavel) => {
  return post(`/api/chamados/${idChamado}/usuario/${novoResponsavel}`, {
    idUsuario: InfoUsuario.get('cdUsuario'),
  });
};

export const saveCategoria = (categoria) => {
  return put(`/api/categorias/${categoria.id || -1}`, categoria);
};

export const changeOrdemCategoria = (id, idCategoria, ordem) => {
  return post(`/api/categorias/${id}/ordem`, {
    idCategoria,
    ordem,
  });
};

export const getAtendimento = (id) => {
  return get(`/api/chamados/atendimento/${id}`);
};

export const getAtendimentos = () => {
  return get('/api/chamados/atendimentos');
};

export const getAtendimentosSelect = () => {
  return getAtendimentos().then((res) => {
    return res.map((item) => ({
      key: item.id,
      value: item.id,
      title: item.nome,
    }));
  });
};

export const saveAtendimento = (atendimento) => {
  if (atendimento.horarios) {
    for (const horario of atendimento.horarios) {
      if (horario.intervalos) {
        horario.intervalos = horario.intervalos.map((intervalo) => omit(intervalo, ['id']));
      }
    }
  }
  return post('/api/chamados/atendimento', atendimento);
};

export const getDashboard = (idUsuario, filtros, search) => {
  return get(`/api/chamados/dashboard/${idUsuario}${queryParamsChamados(filtros, null, search)}`);
};

export const changeLido = (idUsuario, idChamado) => {
  return post('/api/chamados/lido', { idUsuario, idChamado });
};

export const getUsuariosPorUnidade = (options = false, idUnidade) => {
  return get(`/api/chamados/usuarios/${idUnidade}?includeNotActive=false`).then((res) => {

    if (!options) return res;

    return res.map((item) => ({
      key: item.cd_usuario,
      value: item.cd_usuario,
      label: item.nm_usuario,
      title: item.nm_usuario,
      tag: item.perfil ? renderHTMLTagUser(item.perfil) : null,
    }));
  });
};

export const getUsuarios = (options = false, idCategoria, idUnidade) => {
  return get(`/api/categorias/usuarios/${idCategoria}/${idUnidade}?includeNotActive=false`).then((res) => {

    if (!options) return res;

    return res.map((item) => ({
      key: item.cd_usuario,
      value: item.cd_usuario,
      label: item.nm_usuario,
      title: item.nm_usuario,
      tag: item.perfil ? renderHTMLTagUser(item.perfil) : null,
    }));
  });
};

export const getConfiguracao = () => {
  return get('/api/chamados/configuracao');
};

export const saveConfiguracao = (configuracao) => {
  return post('/api/chamados/configuracao', configuracao);
};

export const enableCreationTicket = (idUsuario) => {
  return get(`/api/chamados/create/enable/${idUsuario}`);
};

export const saveQuestionarioReply = (questionario, updateOnlyAnswers = false, userModificationID) => {
  const idUser = userModificationID ? `&userModificationID=${userModificationID}` : '';
  return put(`/api/chamados/questionario?updateOnlyAnswers=${updateOnlyAnswers}${idUser}`, questionario);
};

export const getChamadosFilhosEtapas = (idChamado) => {
  return get(`/api/chamados/etapa/filhos/${idChamado}`);
};

export const changeChamadoTitulo = (idChamado, titulo) => {
  const idUsuario = InfoUsuario.get('cdUsuario');
  return put(`/api/chamados/${idChamado}/assunto?IdUsuario=${idUsuario}`, titulo);
};

export const putCompany = (idChamado, idCompany) => {
  return put(`/api/chamados/${idChamado}/cliente/${idCompany}/usuario/${InfoUsuario.get('cdUsuario')}`);
};

export const getPrintoutOfSteps = (idChamado) => {
  return get(`/api/ticket/download?id=${idChamado}`, { 'responseType': 'blob' }, true);
};