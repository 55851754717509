/* eslint-disable id-length */
import React from 'react';
import { CloseIcon, IconStyled, StyledUpload, UploadDragger, UploadText } from './styles';
import { Row } from '@components-teammove'; 
import { authorization } from '@sdk/api';
import { saveFile } from '@sdk/Files';
import { download, Notification } from '@utils';
import Uploads from './Uploads';
import { Column } from './Uploads/styles';

const getAction = (URI, folder) => `${URI}/api/upload/${encodeURIComponent(folder)}`;

const Upload = ({ showUploadList, uploadsListProps,...props }) => {
  const handleDelete = (deletedFileSource) => {
    const file = props.fileList.find(({ response }) => response === deletedFileSource);
    props.onChange({ file, fileList: props.fileList.filter(({ uid }) => uid !== file.uid) });
  };
  
  return(
    <Column gap='16px'>
      <StyledUpload showUploadList={false} action={getAction(props.URI, props.folder)} { ...props }/>
      {showUploadList && props.fileList && (
        <Uploads 
          uploads={props.fileList.filter(({ status }) => status === 'done').map(({ response, name }) => ({ path: response, name }))}
          onDelete={handleDelete}
          {...uploadsListProps}
        />
      )}
    </Column>
  );
};

const { REACT_APP_API } = window.env;

const Dragger = ({ children, ...props }) => {
  
  const handlePaste = (e) => {
    e.stopPropagation();
    navigator.clipboard.read().then((clipboardItems) => {
      for (const clipboardItem of clipboardItems) {
        clipboardItem.types.forEach((type) => {
          if (type.startsWith('image/')) {
            clipboardItem.getType(type).then((blob) => {
              const file = new File([blob], `image.${type.split('/')[1]}`, { type: blob.type });

              const fileWithUID = { 
                uid: file.uid || null, 
                name: file.name, 
                originFileObj: file,
                status: 'loading',
              };

              const updatedFileList = [...(props.fileList || []), fileWithUID];

              props.onChange({ file: fileWithUID, fileList: updatedFileList });

              saveFile(file, props.folder).then((res) => {
                const updatedFileListWithResponse = updatedFileList.map((fileList) =>
                  fileList.uid === fileWithUID.uid 
                    ? { ...fileList, status: 'done', response: res } 
                    : fileList
                );

                props.onChange({ file: { ...fileWithUID, status: 'done', response: res }, fileList: updatedFileListWithResponse });
              }).catch((err) => {
                const updatedFileListWithError = updatedFileList.map((fileList) =>
                  fileList.uid === fileWithUID.uid ? { ...fileList, status: 'error' } : fileList
                );

                props.onChange({ file: { ...fileWithUID, status: 'error' }, fileList: updatedFileListWithError });
                console.error(err.message);
              });
            }).catch((error) => {
              Notification.info('Não há imagem na área de transferência');
            });
          }
        });
      }
    }).catch((error) => {
      console.error('Failed to read clipboard', error);
    });
  };
  
  return (
    <UploadDragger 
      action={getAction(props.URI || REACT_APP_API, props.folder)}
      listType='picture' 
      showUploadList={{ removeIcon: <CloseIcon/> }} 
      headers={authorization()} 
      URI={REACT_APP_API} 
      onDownload={(file) => download(file.response)} 
      {...props}
    >
      {children || (
        <Column gap='8px' align='center'>
          <Row gap='10%' width='100%' align='center' justify='center'>
            <IconStyled type='upload'/>
            <IconStyled type='copy' onClick={(e) => handlePaste(e)}/>
          </Row>
          <UploadText>Clique ou arraste o arquivo para fazer upload ou no icone copiar da área de transferência</UploadText>
        </Column>
      )}
    </UploadDragger>

  );
};

Upload.Dragger = Dragger;

export default Upload;