import { Map, List } from 'immutable';
import { call, put, takeLatest } from 'redux-saga/effects';
import { Notification } from '@utils';
import { getUserRemotenesses as getUserRemotenessesSdk } from '../../sdk/Usuarios';

//Action Types
export const Types = {
  GET_LIST_BY_USER: 'AFASTAMENTOS/GET_LIST_BY_USER',
  GET_LIST_BY_USER_SUCCESS: 'AFASTAMENTOS/GET_LIST_BY_USER_SUCCESS',
  GET_LIST_BY_USER_ERROR: 'AFASTAMENTOS/GET_LIST_BY_USER_ERROR',

  RESET: 'AFASTAMENTOS/RESET',
  RESET_SUCCESS: 'AFASTAMENTOS/RESET_SUCCESS',
};

//Action Creators
export const getRemotenessesListByUser = () => ({ type: Types.GET_LIST_BY_USER });
export const getRemotenessesListByUserSuccess = (response) => ({ type: Types.GET_LIST_BY_USER_SUCCESS, response });
export const getRemotenessesListByUserError = (error) => ({ type: Types.GET_LIST_BY_USER_ERROR, error });

export const reset = () => ({ type: Types.RESET });
export const resetSuccess = () => ({ type: Types.RESET_SUCCESS });

function* fetchGetRemotenessesListByUser() {
  try {
    const response = yield call(getUserRemotenessesSdk);
    yield put(getRemotenessesListByUserSuccess(response));
  } catch (err) {
    Notification.error(err);
    yield put(getRemotenessesListByUserError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_LIST_BY_USER, fetchGetRemotenessesListByUser),
];

// Reducer
const initialState = Map({
  userRemotenessesList: List(),
  userRemotenessesListTotal: 0,
  loadingUserRemotenessesList: false,
  successUserRemotenessesList: false,
  errorUserRemotenessesList: false,
});

const handleUserRemotenessesList = (state, action) => {
  return state.set('loadingUserRemotenessesList', true).set('successUserRemotenessesList', false).set('errorUserRemotenessesList', false);
};

const handleUserRemotenessesListSuccess = (state, action) => {
  const { response } = action;
  return state.set('userRemotenessesList', response.list).set('userRemotenessesListTotal', response.total).set('loadingUserRemotenessesList', false).set('successUserRemotenessesList', true).set('errorUserRemotenessesList', false);
};

const handleUserRemotenessesListError = (state, action) => {
  return state.set('loadingUserRemotenessesList', false).set('successUserRemotenessesList', false).set('errorUserRemotenessesList', action.error);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_LIST_BY_USER: return handleUserRemotenessesList(state);
    case Types.GET_LIST_BY_USER_SUCCESS: return handleUserRemotenessesListSuccess(state, action);
    case Types.GET_LIST_BY_USER_ERROR: return handleUserRemotenessesListError(state, action);

    case Types.RESET_ALL_VALUES: return initialState;
    case Types.RESET_SUCCESS: return state.set('successUserRemotenessesList', false);
    
    default: return state;
  }
}