import styled from 'styled-components';
import { View, Text, Image } from '@components';
import { Icon , Button } from '@components-teammove';

export const Row = styled(View)`
  flex-direction: row;
  align-items: center;
  ${({ gap }) => gap && `
    gap: ${gap};
  `}
`;

export const ColumnWithGap = styled(View)`
  gap: 14px;
`;

export const Container = styled(View)`
  
`;

export const ContainerLoading = styled(View)`
  >:nth-child(odd) {
    margin-bottom: 8px;
  }
  >:nth-child(even) {
    margin-bottom: 24px;
  }
`;

export const ContainerFormItemHidden = styled(View)`
  display: none;
`;

export const ContentStatusCompletedBriefing = styled(View)`
  width: max-content;
  margin-top: 5px;
`;

export const TextDataToUpdate = styled(Text)`
  color: black !important;
  font-size: 17px;
  font-weight: bold;
  padding: 5px 0px 5px 0px;
`;

export const ContentUpload = styled(Row)`
  flex: 1;
  overflow: auto;
  overflow-y: hidden; 
  justify-content: flex-end;
  margin-right: 10px;
`;

export const ContentImageUpload = styled(View)`
  
`;

export const ButtonDeleteUpload = styled(Button)`
  position: absolute;
  width: 25px;
  height: 25px;
  min-width: 20px !important;
  margin-left: 30px; 
`;

export const ImageUpload = styled(Image)`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

export const IconDeleteUpload = styled(Icon.CloseOutlined)`
  font-size: 12px;
  height: 12px;
  width: 12px;
  vertical-align: 0.2em;
`;

export const IconFileDoneOutlinedUpload = styled(Icon.FileDoneOutlined)`
  font-size: 50px;
  border-radius: 50%;
`;

export const ThinModalTitle = styled(Text)`
  &.ant-typography {
    font-weight: 400;
    color: inherit;
  }
`;

export const DetailText = styled(Text)`
  &.ant-typography {
    font-size: 16px;
    font-weight: 400;
    color: #F5F5F5;
    height: fit-content;
  }
`;

export const DetailLabel = styled(DetailText)`
  &.ant-typography {
    font-weight: 700;
    opacity: 50%;
  }
`;

export const ProtocolText = styled(Text)`
  &.ant-typography {
    font-size: 18px;
    font-weight: 400;
    color: #F5F5F5;
    opacity: 50%;
  }
`;

export const WarningText = styled(Text)`
  &.ant-typography {
    display: flex;
    align-items: center;
    gap: 9.5px;
    padding: 16px;
    background-color: ${({ theme }) => theme.darkSecondaryColor};
    border-radius: 8px;
    font-weight: 400;
    font-size: 12px;
    color: ${({ theme }) => theme.textColor};
    margin-bottom: 20px;

    .danger-color {
      color: var(--danger-color);
    }
  }
`;

export const WarningIcon = styled(Icon.WarningOutlined)`
  &.anticon {
    font-size: 16px;
  }
`;

export const CloseIcon = styled(Icon.Feather)`
  cursor: pointer;
  background-color: ${({ theme }) => theme.darkPrimaryColor};
`;