import { all } from 'redux-saga/effects';

import { saga as login } from '@ducks/login';
import { saga as notificacoes } from '@ducks/notificacoes';
import { saga as perfil } from '@ducks/perfil';
import { saga as alterarSenha } from '@ducks/alterarSenha';
import { saga as recuperarSenha } from '@ducks/recuperarSenha';

import { saga as wikis } from '@ducks/wikis';
import { saga as wikisAudiencia } from '@ducks/wikisAudiencia';
import { saga as wikisAcoes } from '@ducks/wikisAcoes';
import { saga as wikisEditor } from '@ducks/wikisEditor';
import { saga as wikisPersonalizacao } from '@ducks/wikisPersonalizacao';
import { saga as wikisCadastro } from '@ducks/wikisCadastro';
import { saga as wikisComentario } from '@ducks/wikisComentario';
import { saga as wikisComentarioAcao } from '@ducks/wikisComentarioAcao';
import { saga as wikisEAD } from '@ducks/wikisEAD';
import { saga as wikisEADQuiz } from '@ducks/wikisEADQuiz';
import { saga as wikisEADTrilhas } from '@ducks/wikisEADTrilhas';
import { saga as wikisEADTrilha } from '@ducks/wikisEADTrilha';

import { saga as chamadosTickets } from '@ducks/chamadosTickets';
import { saga as chamadosCadastro } from '@ducks/chamadosCadastro';
import { saga as chamadosHistorico } from '@ducks/chamadosHistorico';
import { saga as chamadosDashboard } from '@ducks/chamadosDashboard';
import { saga as chamadosBriefingReply } from '@ducks/chamadosBriefingReply';
import { saga as chamadosEtapas } from '@ducks/chamadosEtapas';

import { saga as classificacoes } from '@ducks/classificacoes';

import { saga as gestaoEquipes } from '@ducks/gestaoEquipes';

import { saga as atividades } from '@ducks/atividades';
import { saga as checklists } from '@ducks/checklists';

import { saga as unidades } from '@ducks/unidades';
import { saga as contatos } from '@ducks/contatos';

import { saga as report } from '@ducks/report';

import { saga as configuracoesChamados } from '@ducks/configuracoesChamados';
import { saga as configuracoesChamadosCategoria } from '@ducks/configuracoesChamadosCategoria';
import { saga as configuracoesChamadosAtendimento } from '@ducks/configuracoesChamadosAtendimento';
import { saga as configuracoesChamadosGeral } from '@ducks/configuracoesChamadosGeral';

import { saga as configuracoesGestaoEquipe } from '@ducks/configuracoesGestaoEquipe';

import { saga as configuracoesUsuarios } from '@ducks/configuracoesUsuarios';
import { saga as configuracoesUsuariosAtributo } from '@ducks/configuracoesUsuariosAtributo';

import { saga as configuracoesLogoImpressao } from '@ducks/configuracoesLogoImpressao';

import { saga as configuracoesAtendimento } from '@ducks/configuracoesAtendimento';

import { saga as configuracoesTelasNomenclaturas } from '@ducks/configuracoesTelasNomenclaturas';

import { saga as afastamento } from '@ducks/afastamento';

import { saga as home } from '@ducks/home';

import { saga as novidades } from '@ducks/novidades';

import { saga as campanhaLista } from '@ducks/campanhaLista';
import { saga as campanhaDetalhe } from '@ducks/campanhaDetalhe';
import { saga as campanhasCadastro } from '@ducks/campanhasCadastro';
import { saga as campanhaImportacaoResultadosExcel } from '@ducks/campanhaImportacaoResultadosExcel';

import { saga as configuracoesUnidadesAgrupadores } from '@ducks/configuracoesUnidadesAgrupadores';
import { saga as configuracoesUnidadesCamposCustomizaveis } from '@ducks/configuracoesUnidadesCamposCustomizaveis';
import { saga as configuracoesUnidadesCamposDinamicos } from '@ducks/configuracoesUnidadesCamposDinamicos';
import { saga as configuracoesUnidadesClusters } from '@ducks/configuracoesUnidadesClusters';
import { saga as configuracoesUnidadesTiposUnidades } from '@ducks/configuracoesUnidadesTiposUnidades';
import { saga as configuracoesUnidadesIdApiDuplicados } from '@ducks/configuracoesUnidadesIdApiDuplicados';

import { saga as configuracoesCampanhasTipos } from '@ducks/configuracoesCampanhasTipos';
import { saga as configuracoesCampanhasAgrupadores } from '@ducks/configuracoesCampanhasAgrupadores';

import { saga as configuracoesReportPerfis } from '@ducks/configuracoesReportPerfis';

import { saga as configuracoesAtividadesTipos } from '@ducks/configuracoesAtividadesTipos';
import { saga as configuracoesMetaAtividades } from '@ducks/configuracoesMetaAtividades';
import { saga as configuracoesWhitelabel } from '@ducks/configuracoesWhitelabel';

import { saga as configuracoesAgrupadoresMetasAtividades } from '@ducks/configuracoesAgrupadoresMetasAtividades';

import { saga as userRemoteness } from '@ducks/userRemoteness';

import { saga as checklistTemplates } from '@ducks/checklistTemplates';

import { saga as pedidos } from '@ducks/pedidos';
import { saga as pedidosDashboard } from '@ducks/pedidosDashboard';
import { saga as ordersKinds } from '@ducks/settingsOrdersOrdersKinds';

import { saga as produtos } from '@ducks/produtos';
import { saga as families } from '@ducks/settingsProductsFamilies';
import { saga as categories } from '@ducks/settingsProductsCategories';
import { saga as priceTables } from '@ducks/settingsProductsPriceTables';
import { saga as extraFields } from '@ducks/settingsProductsExtraFields';

import { saga as integracoes } from '@ducks/integracoes';

import { saga as gestaoCarteira } from '@ducks/gestaoCarteira';
import { saga as atividadesChecklists } from '@ducks/atividadesChecklists';
import { saga as atividadesGestaoAgenda } from '@ducks/atividadesGestaoAgenda';
import { saga as atividadesAtividadesCheckInOut } from '@ducks/atividadesCheckInOut';

import { saga as metas } from '@ducks/metas';
import { saga as activitiesGoals } from '@ducks/activitiesGoals';

import { saga as companyImport } from '@ducks/companyImport';

import { saga as companyDelinquency } from '@ducks/companyDelinquency';

import { saga as companyDelinquencyConfiguration } from '@ducks/companyDelinquencyConfiguration';

import { saga as pendingGeolocationCompanies } from '@ducks/pendingGeolocationCompanies';

import { saga as contentConfiguration } from '@ducks/contentConfig';

import { saga as configurationVoip } from '@ducks/configuracoesVoip';
import { saga as voip } from '@ducks/voip';
import { saga as whatsapp } from './ducks/whatsapp';

import { saga as callActivity } from '@ducks/callActivity';

import { saga as settingsClassificationContacts } from '@ducks/settingsClassificationContacts'; 

function* rootSaga() {
  yield all([
    ...login,
    ...notificacoes,
    ...perfil,
    ...alterarSenha,
    ...recuperarSenha,
    ...wikis,
    ...wikisAcoes,
    ...wikisAudiencia,
    ...wikisEditor,
    ...wikisPersonalizacao,
    ...wikisCadastro,
    ...wikisEAD,
    ...wikisEADQuiz,
    ...wikisEADTrilhas,
    ...wikisEADTrilha,
    ...wikisComentario,
    ...wikisComentarioAcao,
    ...chamadosTickets,
    ...chamadosCadastro,
    ...chamadosHistorico,
    ...chamadosDashboard,
    ...chamadosBriefingReply,
    ...atividades,
    ...checklists,
    ...configuracoesChamados,
    ...gestaoEquipes,
    ...unidades,
    ...contatos,
    ...report,
    ...configuracoesChamadosCategoria,
    ...configuracoesChamadosAtendimento,
    ...configuracoesGestaoEquipe,
    ...configuracoesChamadosGeral,
    ...configuracoesUsuarios,
    ...configuracoesUsuariosAtributo,
    ...configuracoesTelasNomenclaturas,
    ...afastamento,
    ...home,
    ...novidades,
    ...campanhaLista,
    ...campanhaDetalhe,
    ...campanhasCadastro,
    ...campanhaImportacaoResultadosExcel,
    ...configuracoesUnidadesAgrupadores,
    ...configuracoesUnidadesCamposCustomizaveis,
    ...configuracoesUnidadesCamposDinamicos,
    ...configuracoesUnidadesClusters,
    ...configuracoesUnidadesTiposUnidades,
    ...configuracoesUnidadesIdApiDuplicados,
    ...configuracoesCampanhasTipos,
    ...configuracoesCampanhasAgrupadores,
    ...configuracoesReportPerfis,
    ...configuracoesAtividadesTipos,
    ...configuracoesWhitelabel,
    ...userRemoteness,
    ...checklistTemplates,
    ...pedidos,
    ...produtos,
    ...families,
    ...categories,
    ...priceTables,
    ...extraFields,
    ...ordersKinds,
    ...integracoes,
    ...chamadosEtapas,
    ...gestaoCarteira,
    ...classificacoes,
    ...atividadesChecklists,
    ...configuracoesLogoImpressao,
    ...configuracoesAtendimento,
    ...pedidosDashboard,
    ...atividadesGestaoAgenda,
    ...configuracoesMetaAtividades,
    ...atividadesAtividadesCheckInOut,
    ...metas,
    ...activitiesGoals,
    ...companyImport,
    ...companyDelinquency,
    ...companyDelinquencyConfiguration,
    ...pendingGeolocationCompanies,
    ...contentConfiguration,
    ...configurationVoip,
    ...voip,
    ...whatsapp,
    ...callActivity,
    ...settingsClassificationContacts,
    ...configuracoesAgrupadoresMetasAtividades,
  ]);
}

export default rootSaga;
