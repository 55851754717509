import { get, post } from '../api';
import { InfoUsuario, dateToAnsi } from '@utils';

export const getUsuarios = () => {
  return get(`/api/usuarios/v${InfoUsuario.get('cdUsuario')}`).then((res) => {
    return res.map((item) => ({
      key: item.cd_usuario,
      value: item.cd_usuario,
      title: item.nm_usuario + (item.perfil ? ' - ' + item.perfil.descricao : ''),
      idPerfil: item.idPerfil,
      unidades: item.unidades && item.unidades.split(','),
      email: item.email,
    }));
  });
};

export const getUsuario = ({ cdUsuario }) => {
  return get(`/api/usuario/${cdUsuario}`);
};

export const getAtributos = () => {
  return get('/api/usuario/atributos');
};

export const getAtributosSelect = () => {
  return getAtributos().then((res) => {
    return res
      .filter((item) => item.ativo)
      .map((item) => ({
        key: item.id,
        value: item.id,
        title: item.atributo,
      }));
  });
};

export const getAtributosUsuario = (idUsuario = InfoUsuario.get('cdUsuario')) => {
  return get(`/api/usuario/atributos/${idUsuario}`);
};

export const saveAtributo = (atributo) => {
  return post('/api/usuario/atributo', atributo);
};

export const registerUserControl = (idUser = InfoUsuario.get('cdUsuario')) => {
  return post('/rest/user/control', { idUser, dateAccess: dateToAnsi(new Date()) });
};

export const getDuplicatedIdApi = () => {
  return get('/api/users/duplicated/idapi');
};

export const getUserRemotenesses = () => {
  return get(`/api/user/${InfoUsuario.get('cdUsuario')}/remotenesses?profileId=${InfoUsuario.get('idPerfil')}`, {}, true);
};