import React from 'react';
import { Form, Input, InputMask, Select, DatePicker, InternationalPhone } from '@components-teammove';
import { customFields, customFieldsWithList } from '../../rules';

const ConditionalCustomField = ({ customField, unidadeToSend, setUnidadeToSend, form }) => {
  const isCellphone = customField.field === 'CELULAR_1' || customField.field === 'CELULAR_2';
  const isTelephone = customField.field === 'TELEFONE_1' || customField.field === 'TELEFONE_2';

  return (
    <Form form={form} layout='vertical'>
      {customField.preview && customField.field === 'INAUGURACAO' ? (
        <Form.Item 
          name={customFields[customField.field]} 
          key={customField.ordem} 
          required={customField.required} 
          rules={[{ required: customField.required, message: 'Insira um valor para o campo' }]} 
          label={customField.name} 
          colon={false}
        >
          <DatePicker format='DD/MM/YYYY' onChange={(date) => setUnidadeToSend({ ...unidadeToSend, [customFields[customField.field]]: date })}/>
        </Form.Item>
      ) : isTelephone || isCellphone ? (
        <Form.Item 
          name={customFields[customField.field]}
          key={customField.ordem} 
          required={customField.required} 
          rules={customField.required && [
            { required: true, message: 'Insira um valor para o campo' },
          ]} 
          label={customField.name} 
          colon={false}
        >
          <InternationalPhone
            fromCompanies
            onChange={(value, countryCode) => setUnidadeToSend({ ...unidadeToSend, [customFields[customField.field]]: value, countryCode })}
          />
        </Form.Item>
      ) : (customField.field === 'IE') ? (
        <Form.Item 
          name={customFields[customField.field]} 
          key={customField.ordem} 
          required={customField.required} 
          rules={customField.required && [{ required: true, message: 'Insira um valor para o campo' }, { min: 12, message: 'Insira um valor válido para o campo' }]} 
          label={customField.name} 
          colon={false}
          normalize={(value) => value?.toUpperCase()}
        >
          <Input maxLength={30} onChange={(evt) => setUnidadeToSend({ ...unidadeToSend, [customFields[customField.field]]: evt.target.value.toUpperCase() })}/>
        </Form.Item>
      ) : (customField.field === 'CNPJ') ? (
        <Form.Item 
          name={customFields[customField.field]} 
          key={customField.ordem} 
          required={customField.required} 
          rules={customField.required && [{ required: true, min: 14, message: 'Insira um valor para o campo' }, { min: 14, message: 'Insira um valor válido para o campo' }]} 
          label={customField.name} 
          colon={false}
          normalize={(value) => value.replaceAll(/[^0-9a-z]/gi, '')}
        >
          <InputMask mask={'99.999.999/9999-99'} placeholder={'11.111.111/1111-11'} onChange={(evt) => setUnidadeToSend({ ...unidadeToSend, [customFields[customField.field]]: evt?.target?.value })}/>
        </Form.Item>
      ) : (customField.field === 'METRAGEM') || (customField.field === 'PARTICIPACAO') ? (
        <Form.Item 
          name={customFields[customField.field]} 
          key={customField.ordem} 
          required={customField.required} 
          rules={[{ required: customField.required, message: 'Insira um valor para o campo' }]} 
          label={customField.name} 
          colon={false}
          normalize={(value) => value.replaceAll(/[^0-9a-z]/gi, '')}
        >
          <Input autocomplete='off' type='number' onChange={(evt) => setUnidadeToSend({ ...unidadeToSend, [customFields[customField.field]]: evt?.target?.value })}/>
        </Form.Item>
      ) : customFieldsWithList.includes(customField.field) ? (
        <Form.Item
          name={customFields[customField.field]}
          key={customField.field}
          required={customField.required}
          rules={[{ required: customField.required, message: 'Insira um valor para o campo' }]}
          label={customField.name}
          colon={false}
        >
          <Select placeholder={customField.name} options={customField.options?.map((item, index) => ({ label: item, value: item, key: index }))} onChange={(value) => setUnidadeToSend({ ...unidadeToSend, [customFields[customField.field]]: value })}/>
        </Form.Item>
      ) : (
        <Form.Item 
          name={customFields[customField.field]} 
          key={customField.ordem} 
          required={customField.required} 
          rules={[{ required: customField.required, message: 'Insira um valor para o campo' }]} 
          label={customField.name} 
          colon={false}
        >
          <Input 
            autocomplete='off'
            placeholder={customField.name} 
            onChange={(evt) => setUnidadeToSend({ ...unidadeToSend, [customFields[customField.field]]: evt?.target?.value })}
          />
        </Form.Item>
      )
      }
    </Form>
  );
};

export default ConditionalCustomField;