import { get, put, del } from '../api';
import { getQueryParamsChecklistTemplate } from './rules';

export const getChecklistTemplates = (params) => {
  return get(`/api/checklistTemplates?${getQueryParamsChecklistTemplate(params)}`, {}, true);
};

export const getChecklistTemplate = (id) => {
  return get(`/api/checklistTemplate/${id}`, {}, true);
};

export const getChecklistTemplateConfiguration = () => {
  return get('/api/checklistTemplate/configuration', {}, true);
};

export const putChecklistTemplateConfiguration = (params) => {
  return put('/api/checklistTemplate/configuration', params, {}, true);
};

export const putChecklistTemplate = (params) => {
  return put('/api/checklistTemplate', params, {}, true);
};

export const delChecklistTemplate = (id) => {
  return del(`/api/checklistTemplate/${id}`, {}, true);
};