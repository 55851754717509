import styled from 'styled-components';
import { Text, Select, Form, Button, Column } from '@components-teammove';

export const DesvioCondicionalText = styled(Text)`
    font-size: 16px;
    color: ${({ theme }) => theme.textColor};
    font-weight: 400;
    margin-bottom: 12px;
    text-decoration: underline;
    display: block;
    cursor: pointer;
`;

export const TextConditionalStyled = styled(Text)`
    font-size: 14px;
    color: ${({ theme }) => theme.textColor};
    font-weight: 400;
    margin-left: 8px;
`;

export const SelectStyled = styled(Select)`
    width: 100% !important;
    max-width: 470px !important;
    min-width: 240px !important;
    .ant-select-selector {
        max-width: 440px !important;
    }
`;

export const FormItemStyledWidth = styled(Form.Item)`
    width: 100%;
    max-width: ${({ maxWidth }) => maxWidth || '240px'} !important;
`;

export const ButtonStyled = styled(Button)`
    margin-bottom: 12px;
`;

export const RowStyledConditional = styled(Column)`
    display: ${({ hasToRender }) => (hasToRender ? 'flex' : 'none')};
`;

export const ColumnCardOption = styled(Column)`
    border: 2px solid ${({ theme }) => theme.darkHighlightColor};
    border-radius: 4px; 
    padding: 12px;
    margin-bottom: 12px;
`;