/* eslint-disable camelcase */
import { Form, InputNumber, Modal, Select, Switch, TagInput, Row } from '@components-teammove';
import React, { useEffect, useState } from 'react';
import { FormItemHeaderModal, InputStyledNoBox } from '../styles';
import AnexosPergunta from './Anexos';
import MultiSelect from './MultiSelect';
import { FormItemStyledWidth } from './MultiSelect/styles';
import Numerico from './Numerico';
import { optionsSelectInOptions, optionTypeActivity } from './rules';
import { FormItemDynamic, FormItemStyled } from './styles';

const NovaPerguntaGrupoChecklist = ({ visible, onCancel, handleAddAsk, formPerguntas, groups, groupId }) => {
  const [selectedActivityType, setSelectedActivityType] = useState(formPerguntas.getFieldValue('tipo') || '');
  const [anexosOpcao, setAnexosOpcao] = useState({});
  
  const isMultiSelect = selectedActivityType === 'CAIXA_SELECAO' || selectedActivityType === 'MULTIPLA_ESCOLHA';
  const hasObservation = selectedActivityType === 'DATA' || selectedActivityType === 'HORARIO' || selectedActivityType === 'ANEXO' || selectedActivityType === 'NUMERICO';
  const isAnexo = selectedActivityType === 'ANEXO';
  
  const groupsOptions = groups.map((group) => ({ value: group.temp_id || group.id, label: group.nome }));

  useEffect(() => {
    const isEditing = formPerguntas.getFieldValue('temp_id') || formPerguntas.getFieldValue('id');
    const { tipo_upload, tipo_observacao, utiliza_ultima_resposta, tipo } = formPerguntas.getFieldsValue(true);
    const initialValues = {
      utiliza_ultima_resposta: utiliza_ultima_resposta,
      tipo_upload: tipo_upload || 'NAO_PERMITE',
      tipo_observacao: tipo_observacao || 'NAO_PERMITE',
      grupo_pertencente: groupId
    };

    if(isEditing) {
      setAnexosOpcao((prevState) => {
        const newState = { ...prevState };
        //O -1 é usado somente quando o anexo não está em uma opção
        newState[-1] = (tipo_upload && tipo_upload !== 'NAO_PERMITE') || tipo === 'ANEXO';
        return newState;
      });
    }

    formPerguntas.setFieldsValue(initialValues);
  }, []);

  const handleOpenUploadTypeOptions = (value, index = -1) => {
    setAnexosOpcao((prevState) => {
      const newState = { ...prevState };
  
      if (value === 'NAO_PERMITE') {
        formPerguntas.setFieldsValue({ 'opcoes_anexo': [] });
        delete newState[index]; 
      } else {
        newState[index] = true;
      }
  
      return newState;
    });
  };

  const handleActivityTypeChange = (value) => {
    setAnexosOpcao((prevState) => {
      const newState = { ...prevState };
      newState[-1] = value === 'ANEXO';
      return newState;
    });
    setSelectedActivityType(value);
  };

  const renderTypeQuestion = (type) => {
    const multiSelectProps = {
      handleOpenHintAttachment: handleOpenUploadTypeOptions,
      anexosOpcao,
      form: formPerguntas,
      groups,
      isUniqueChoiceType: type === 'CAIXA_SELECAO',
      setAnexosOpcao: setAnexosOpcao
    };

    switch (type) {
      case 'NUMERICO':
        return <Numerico form={formPerguntas} />;
      case 'ANEXO':
        return <AnexosPergunta anexosOpcao form={formPerguntas}/>;
      case 'CAIXA_SELECAO':
      case 'ESCOLHA_UNICA':
      case 'MULTIPLA_ESCOLHA':
        return (
          <MultiSelect {...multiSelectProps} />
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title=''
      cancelText='Cancelar'
      okText='Salvar'
      onOk={handleAddAsk}
      onBeforeCancel={() => formPerguntas.isFieldsTouched()}
    >
      <Form form={formPerguntas} layout='vertical'>
        <FormItemHeaderModal name="titulo" rules={[{ required: true, message: 'Por favor, insira o nome da pergunta' }]}>
          <InputStyledNoBox placeholder='Nome da pergunta' title />
        </FormItemHeaderModal>
        <FormItemHeaderModal name="observacao">
          <InputStyledNoBox placeholder='Adicionar descrição da atividade' />
        </FormItemHeaderModal>
        <Row align='center' gap='12px'>
          <FormItemStyled label="Opcional" name="opcional" valuePropName='checked'>
            <Switch />
          </FormItemStyled>
          <FormItemStyled important label="Destaque" name="importante" valuePropName='checked'>
            <Switch />
          </FormItemStyled>
          <FormItemStyled label="Utilizar última resposta" name="utiliza_ultima_resposta" valuePropName='checked'>
            <Switch />
          </FormItemStyled>
        </Row>
        <Form.Item hidden name='id'/>
        <Form.Item hidden name='temp_id'/>
        {isMultiSelect && (
          <FormItemStyledWidth label="Peso" name="peso" maxWidth="80px">
            <InputNumber placeholder='peso'/>
          </FormItemStyledWidth>
        )}
        <Row align='center' gap='12px'>
          <Form.Item label="Tipo de Pergunta" name="tipo" rules={[{ required: true, message: 'Por favor, insira o tipo de atividade' }]}>
            <Select placeholder='Selecione uma opção' options={optionTypeActivity} onChange={handleActivityTypeChange} />
          </Form.Item>
          <Form.Item name='grupo_pertencente' label="Grupo">
            <Select options={groupsOptions}/>
          </Form.Item>
        </Row>
        {renderTypeQuestion(selectedActivityType)}
        <FormItemDynamic hasToRender={!isMultiSelect && !isAnexo} label="Anexos" name="tipo_upload">
          <Select options={optionsSelectInOptions} onChange={(value) => handleOpenUploadTypeOptions(value)} />
        </FormItemDynamic>
        <AnexosPergunta anexosOpcao={(!isMultiSelect) && anexosOpcao} form={formPerguntas} />
        <FormItemDynamic hasToRender={hasObservation} label="Observacao" name="tipo_observacao">
          <Select options={optionsSelectInOptions} />
        </FormItemDynamic>
        <Form.Item label="Tags" name="tags">
          <TagInput onChange={(tags) => formPerguntas.setFieldsValue({ tags })} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NovaPerguntaGrupoChecklist;