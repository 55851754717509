/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { Form, Input, InputNumber, Radio, Checkbox, DatePicker, TimePicker, Upload, Select, Tooltip, Column } from '@components-teammove';
import { ExtraInfoContainer, ExtraInfoLabel, ExtraInfoSubLabel } from './styles';
import { Row } from '../../styles';
import { InfoUsuario, getValueFromFile, moment, getIdTemp } from '@utils';
import { HelpIcon, HelpText } from '../styles';
import { useDispatch, useSelector } from 'react-redux';
import { authorization } from '@sdk/api';
import { getTiposAtividadesPlanosAcao } from '@ducks/configuracoesAtividadesTipos';
import { ActivityIcon, ActivityName, IconWrapper } from '../../../../Cadastro/styles';

const { REACT_APP_API } = window.env;

export default function AnswerInput({ form, question, historic, setLoadingUpload }) {
  const dispatch = useDispatch();

  const activitiesTypes = useSelector(({ configuracoesAtividadesTipos }) => configuracoesAtividadesTipos.get('tiposAtividadesPlanosAcao'));
  const loadingActivitiesTypes = useSelector(({ configuracoesAtividadesTipos }) => configuracoesAtividadesTipos.get('loadingTiposAtividadesPlanosAcao'));
  const loadingHistoric = useSelector(({ checklists }) => checklists.get('loadingAnswersHistoric'));
  const successHistoric = useSelector(({ checklists }) => checklists.get('successAnswersHistoric'));

  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    dispatch(getTiposAtividadesPlanosAcao());
  }, []);

  useEffect(() => {
    if (question?.useHistoricAnswer && !question?.answers?.some(({ value }) => value || value === 'true') && historic) {
      if(question?.type === 'MULTIPLA_ESCOLHA' || question?.type === 'CAIXA_SELECAO') {
        let valueToAtribute = null;
        question?.options?.forEach((option) => {
          if(option.title === historic?.answer) {
            valueToAtribute = option.id;
          }
        });
        question?.answers?.forEach((answer) => {
          answer.value = [valueToAtribute];
        });
      }
      else {
        question?.answers?.forEach((answer) => {
          answer.value = question?.type === 'ANEXO' ? [
            { 
              url: historic?.answer,
              response: historic?.answer,
              uid: getIdTemp(),
              idTemp: getIdTemp(),
              status: 'done',
              name: historic?.answer
            }
          ] : historic?.answer;
          form.setFieldsValue({ observation: historic?.observation });
        });
      }
    }

    question?.answers?.forEach(({ value, idAskOption, observation, idUserActivity, titleActivity, dateActivity, responsibleActivity, observationActivity, idTypeActivity, uploads }) => {
      if (idAskOption && value === 'true') {
        setSelectedOptions([...selectedOptions, idAskOption]);
      } else if (question.type === 'MULTIPLA_ESCOLHA') {
        setSelectedOptions([parseInt(value)]);
      }

      if (idAskOption && (value === 'false' || value === false)) {
        return;
      }
      
      form.setFieldsValue({
        answer: value && (idAskOption && (value === 'true' || value === true) ? [...(form.getFieldValue('answer') || []), idAskOption ] :
          question.type === 'DATA' ? moment(value, 'DD/MM/YYYY') :
            question.type === 'HORARIO' ? moment(value, 'HH:mm') :
              question.type === 'CAIXA_SELECAO' ? parseInt(value) : value),

        [(idAskOption || question.type === 'MULTIPLA_ESCOLHA' || question.type === 'CAIXA_SELECAO') && `option-${idAskOption || value}-observation`]: historic?.observation || observation,
        [idAskOption || question.type === 'MULTIPLA_ESCOLHA' || question.type === 'CAIXA_SELECAO' ? `option-${idAskOption || value}-idUserActivity` : 'idUserActivity']: idUserActivity,
        [idAskOption || question.type === 'MULTIPLA_ESCOLHA' || question.type === 'CAIXA_SELECAO' ? `option-${idAskOption || value}-titleActivity` : 'titleActivity']: historic?.taskTitle || titleActivity,
        [idAskOption || question.type === 'MULTIPLA_ESCOLHA' || question.type === 'CAIXA_SELECAO' ? `option-${idAskOption || value}-dateActivity` : 'dateActivity']: historic?.date ? moment(historic?.date) : moment(dateActivity),
        [idAskOption || question.type === 'MULTIPLA_ESCOLHA' || question.type === 'CAIXA_SELECAO' ? `option-${idAskOption || value}-responsibleActivity` : 'responsibleActivity']: historic?.taskAssignee || responsibleActivity,
        [idAskOption || question.type === 'MULTIPLA_ESCOLHA' || question.type === 'CAIXA_SELECAO' ? `option-${idAskOption || value}-observationActivity` : 'observationActivity']: historic?.taskObservation || observationActivity,
        [idAskOption || question.type === 'MULTIPLA_ESCOLHA' || question.type === 'CAIXA_SELECAO' ? `option-${idAskOption || value}-idTypeActivity` : 'idTypeActivity']: historic?.taskKindId || idTypeActivity || question.defaultActivityType,
      });

      const uploadsSeparatedByAnswer = uploads?.reduce((state, upload) => {
        return ({ ...state, [upload.idAskOption]: state[upload.idAskOption] ? [...state[upload.idAskOption], upload] : [upload] });
      }, {}) || {};

      Object.keys(uploadsSeparatedByAnswer).forEach((key) => {
        const answerUploads = {
          [question.type === 'ANEXO' ? 'answer' : idAskOption || question.type === 'MULTIPLA_ESCOLHA' ? `option-${idAskOption || value}-uploads` : 'upload']: uploadsSeparatedByAnswer[key].map(({ url, idUpload, idTemp }) => ({ name: url, url, status: 'done', uid: idUpload, idTemp })),
        };
        form.setFieldsValue(answerUploads);
      });
    });
  }, [question, successHistoric]);

  const handleChangeAttachment = (info, fieldName = 'upload') => {
    if (info.file.status === 'done') {
      const files = [];
      for (const file of info.fileList) {
        files.push(file);
      }
      form.setFieldsValue({ [fieldName]: files });
      setLoadingUpload(false);
    } else if (info.file.status === 'uploading') {
      setLoadingUpload(true);
    }
  };

  const renderActivityTypeOption = (activityType) => (
    <Row gap='15px' align='center'>
      <IconWrapper color={activityType.color}>
        <ActivityIcon type={activityType.icon}/>
      </IconWrapper>
      <ActivityName>{activityType.name}</ActivityName>
    </Row>
  );

  const customValidator = (rule, value) => {
    if (parseFloat(String(value).replaceAll(',', '.')) < question?.minimalInterval) {
      return Promise.reject(new Error('O limite mínimo para esta resposta é de ' + question?.minimalInterval));
    }
    if (parseFloat(String(value).replaceAll(',', '.')) > question?.maxInterval) {
      return Promise.reject(new Error('O limite máximo para esta resposta é de ' + question?.maxInterval));
    }
    return Promise.resolve();
  };

  return (
    <Form form={form} layout='vertical'>
      {/* normalize={question?.type === 'NUMERICO' && ((value) => formatValueToNumberOfDecimals(String(value), question?.decimalFormat))} */}
      <Form.Item 
        name='answer' 
        valuePropName={question?.type === 'ANEXO' ? 'fileList' : 'value'} 
        getValueFromEvent={question?.type === 'ANEXO' ? getValueFromFile : null} 
        rules={
          (question?.minimalInterval || question?.maxInterval || !question?.optional) && [
            !question?.optional && { required: true, message: 'Resposta obrigatória' },
            question?.type === 'NUMERICO' && (({ getFieldValue }) => ({ validator: customValidator }))
          ].filter((rule) => rule)
        }
      >
        {question?.type === 'CAIXA_SELECAO' ? ( 
          <Radio.Group vertical onChange={(value) => setSelectedOptions([value])}>
            {question?.options?.map((option) => (
              <Radio key={option.id} value={option.id} label={option.title}>
                {option.typeObservation !== 'NAO_PERMITE' && selectedOptions.includes(option.id) && (
                  <ExtraInfoContainer>
                    <Column gap='15px'>
                      <Column gap='5px'>
                        <ExtraInfoLabel>
                          <Row>
                            {option.hintObservation || 'Observação'}
                            {option.typeObservation === 'OBRIGATORIO' && (<span className='required-mark'>*</span>)}
                          </Row>
                        </ExtraInfoLabel>
                        
                      </Column>
                      <Form.Item marginless form={form} name={`option-${option.id}-observation`} rules={option.typeObservation === 'OBRIGATORIO' && [{ required: true, message: 'Campo obrigatório' }]} >
                        <Input placeholder={option.observationHint || 'Escreva sua observação aqui'}/>
                      </Form.Item>
                    </Column>
                  </ExtraInfoContainer>
                )}
                {option.typeUpload !== 'NAO_PERMITE' && selectedOptions.includes(option.id) && (
                  <ExtraInfoContainer>
                    <Column gap='15px'>
                      <Column gap='5px'>
                        <ExtraInfoLabel>
                          <Row>
                        Anexo
                            {option.typeUpload === 'OBRIGATORIO' && (<span className='required-mark'>*</span>)}
                          </Row>
                          {option.hintUpload && (
                            <Row gap='6px' align='center'>
                              <HelpText>Ajuda</HelpText>
                              <Tooltip title={option.hintUpload}>
                                <HelpIcon type='help-circle'/>
                              </Tooltip>
                            </Row>
                          )}
                        </ExtraInfoLabel>
                        <ExtraInfoSubLabel>Adicione um anexo</ExtraInfoSubLabel>
                      </Column>
                      <Form.Item marginless form={form} valuePropName='fileList' name={`option-${option.id}-uploads`} getValueFromEvent={getValueFromFile} rules={option.typeUpload === 'OBRIGATORIO' && [{ required: true, message: 'Observação obrigatória' }]} >
                        <Upload.Dragger
                          folder={`Chamados/${InfoUsuario.get('cdCliente')}`}
                          multiple
                          URI={REACT_APP_API}
                          headers={authorization()}
                          onChange={(info) => handleChangeAttachment(info, `option-${option.id}-uploads`)}
                        />
                      </Form.Item>
                    </Column>
                  </ExtraInfoContainer>
                )}
                {option.createActivity !== 'NAO_PERMITE' && selectedOptions.includes(option.id) && (
                  <ExtraInfoContainer>
                    <Column gap='15px'>
                      <Column gap='5px'>
                        <ExtraInfoLabel>
                          <Row>
                            Plano de ação
                            {option.createActivity === 'OBRIGATORIO' && (<span className='required-mark'>*</span>)}
                          </Row>
                          {option.hintAsk && (
                            <Row gap='6px' align='center'>
                              <HelpText>Ajuda</HelpText>
                              <Tooltip title={option.hintAsk}>
                                <HelpIcon type='help-circle'/>
                              </Tooltip>
                            </Row>
                          )}
                        </ExtraInfoLabel>
                        <ExtraInfoSubLabel>{option.hintAsk || 'Ação corretiva'}</ExtraInfoSubLabel>
                      </Column>
                      <Form.Item marginless label='Tipo de atividade' form={form} name={`option-${option.id}-idTypeActivity`} rules={option.createActivity === 'OBRIGATORIO' && [{ required: true, message: 'Observação obrigatória' }]}>
                        <Select 
                          placeholder='Tipo de atividade' 
                          options={activitiesTypes?.map((activityType) => ({ label: renderActivityTypeOption(activityType), value: activityType.id }))}
                          loading={loadingActivitiesTypes}
                          fitContent
                        />
                      </Form.Item>
                      <Form.Item marginless label='Objetivo' form={form} name={`option-${option.id}-titleActivity`} rules={option.createActivity === 'OBRIGATORIO' && [{ required: true, message: 'Título obrigatório' }]}>
                        <Input placeholder='Por que será feito'/>
                      </Form.Item>
                      {/* {question.useGoal && (
                        <Form.Item marginless label='Objetivo' form={form} name={`option-${option.id}-titleActivity`} rules={option.createActivity === 'OBRIGATORIO' && [{ required: true, message: 'Observação obrigatória' }]}>
                          <Input placeholder='Por que será feito'/>
                        </Form.Item>
                      )} */}
                      <Row gap='12px'>
                        <Form.Item marginless label='Quem' form={form} name={`option-${option.id}-responsibleActivity`} rules={option.createActivity === 'OBRIGATORIO' && [{ required: true, message: 'Responsável obrigatório' }]}>
                          {question.useRegisteredUser ? (
                            <Select placeholder='Quem fará' options={question.registeredUsersOptions.map((userName) => ({ label: userName, value: userName }))}/>
                          ) : (
                            <Input placeholder='Quem fará'/>
                          )}
                        </Form.Item>
                        {question.useDate && (
                          <Form.Item marginless label='Quando' form={form} name={`option-${option.id}-dateActivity`} rules={option.createActivity === 'OBRIGATORIO' && [{ required: true, message: 'Data obrigatória' }]}>
                            <DatePicker placeholder='Data e hora' format='DD/MM/YYYY HH:mm' showTime/>
                          </Form.Item>
                        )}
                      </Row>
                      {question.useObservation && (
                        <Form.Item marginless label='Como' form={form} name={`option-${option.id}-observationActivity`}>
                          <Input.TextArea placeholder='Como será feito'/>
                        </Form.Item>
                      )}
                    </Column>
                  </ExtraInfoContainer>
                )}
              </Radio>
            ))}
          </Radio.Group>
        ) : question?.type === 'MULTIPLA_ESCOLHA' ? (
          <Checkbox.Group vertical onChange={(values) => setSelectedOptions(values)}>
            {question?.options?.map((option) => (
              <Checkbox key={option.id} label={option.title} value={option.id}>
                {option.typeObservation !== 'NAO_PERMITE' && selectedOptions.includes(option.id) && (
                  <ExtraInfoContainer>
                    <Column gap='15px'>
                      <Column gap='5px'>
                        <ExtraInfoLabel>
                          <Row>
                            {option.hintObservation || 'Observação'}
                            {option.typeObservation === 'OBRIGATORIO' && (<span className='required-mark'>*</span>)}
                          </Row>
                        </ExtraInfoLabel>
                      </Column>
                      <Form.Item marginless form={form} name={`option-${option.id}-observation`} rules={option.typeObservation === 'OBRIGATORIO' && [{ required: true, message: 'Campo obrigatório' }]} >
                        <Input placeholder={option.observationHint || 'Escreva sua observação aqui'}/>
                      </Form.Item>
                    </Column>
                  </ExtraInfoContainer>
                )}
                {option.typeUpload !== 'NAO_PERMITE' && selectedOptions.includes(option.id) && (
                  <ExtraInfoContainer>
                    <Column gap='15px'>
                      <Column gap='5px'>
                        <ExtraInfoLabel>
                          <Row>
                          Anexo
                            {option.typeUpload === 'OBRIGATORIO' && (<span className='required-mark'>*</span>)}
                          </Row>
                          {option.hintUpload && (
                            <Row gap='6px' align='center'>
                              <HelpText>Ajuda</HelpText>
                              <Tooltip title={option.hintUpload}>
                                <HelpIcon type='help-circle'/>
                              </Tooltip>
                            </Row>
                          )}
                        </ExtraInfoLabel>
                        <ExtraInfoSubLabel>Adicione um anexo</ExtraInfoSubLabel>
                      </Column>
                      <Form.Item marginless form={form} valuePropName='fileList' name={`option-${option.id}-uploads`} getValueFromEvent={getValueFromFile} rules={option.typeUpload === 'OBRIGATORIO' && [{ required: true, message: 'Observação obrigatória' }]} >
                        <Upload.Dragger
                          folder={`Chamados/${InfoUsuario.get('cdCliente')}`}                          
                          multiple
                          onChange={(info) => handleChangeAttachment(info, `option-${option.id}-uploads`)}
                        />
                      </Form.Item>
                    </Column>
                  </ExtraInfoContainer>
                )}
                {option.createActivity !== 'NAO_PERMITE' && selectedOptions.includes(option.id) && (
                  <ExtraInfoContainer>
                    <Column gap='15px'>
                      <Column gap='5px'>
                        <ExtraInfoLabel>
                          <Row>
                            Plano de ação
                            {option.createActivity === 'OBRIGATORIO' && (<span className='required-mark'>*</span>)}
                          </Row>
                          {option.hintAsk && (
                            <Row gap='6px' align='center'>
                              <HelpText>Ajuda</HelpText>
                              <Tooltip title={option.hintAsk}>
                                <HelpIcon type='help-circle'/>
                              </Tooltip>
                            </Row>
                          )}
                        </ExtraInfoLabel>
                        <ExtraInfoSubLabel>{option.hintAsk || 'Ação corretiva'}</ExtraInfoSubLabel>
                      </Column>
                      <Form.Item marginless label='Tipo de atividade' form={form} name={`option-${option.id}-idTypeActivity`} rules={option.createActivity === 'OBRIGATORIO' && [{ required: true, message: 'Observação obrigatória' }]}>
                        <Select 
                          placeholder='Tipo de atividade' 
                          options={activitiesTypes?.map((activityType) => ({ label: renderActivityTypeOption(activityType), value: activityType.id }))}
                          loading={loadingActivitiesTypes}
                          fitContent
                        />
                      </Form.Item>
                      <Form.Item marginless label='O quê' form={form} name={`option-${option.id}-titleActivity`} rules={option.createActivity === 'OBRIGATORIO' && [{ required: true, message: 'Objetivo obrigatório' }]}>
                        <Input placeholder='Por que será feito'/>
                      </Form.Item>
                      {/* {question.useGoal && (
                        <Form.Item marginless label='Objetivo' form={form} name={`option-${option.id}-goalActivity`} rules={option.createActivity === 'OBRIGATORIO' && [{ required: true, message: 'Objetivo obrigatório' }]}>
                          <Input placeholder='Por que será feito'/>
                        </Form.Item>
                      )} */}
                      <Row gap='12px'>
                        <Form.Item marginless label='Quem' form={form} name={`option-${option.id}-responsibleActivity`} rules={option.createActivity === 'OBRIGATORIO' && [{ required: true, message: 'Responsável obrigatório' }]}>
                          {question.useRegisteredUser ? (
                            <Select placeholder='Quem fará' options={question.registeredUsersOptions.map((userName) => ({ label: userName, value: userName }))}/>
                          ) : (
                            <Input placeholder='Quem fará'/>
                          )}
                        </Form.Item>
                        {question.useDate && (
                          <Form.Item marginless label='Quando' form={form} name={`option-${option.id}-dateActivity`} rules={option.createActivity === 'OBRIGATORIO' && [{ required: true, message: 'Data obrigatória' }]}>
                            <DatePicker placeholder='Data e hora' format='DD/MM/YYYY HH:mm' showTime/>
                          </Form.Item>
                        )}
                      </Row>
                      {question.useObservation && (
                        <Form.Item marginless label='Como' form={form} name={`option-${option.id}-observationActivity`}>
                          <Input.TextArea placeholder='Como será feito'/>
                        </Form.Item>
                      )}
                    </Column>
                  </ExtraInfoContainer>
                )}
              </Checkbox>
            ))}
          </Checkbox.Group>
        ) : question?.type === 'TEXTO' ? (
          <Input placeholder='Escreva sua resposta aqui' loading={loadingHistoric} autoFocus/>
        ) : question?.type === 'TEXTO_PARAGRAFO' ? (
          <Input.TextArea placeholder='Escreva sua resposta aqui' loading={loadingHistoric} autoFocus/>
        ) : question?.type === 'DATA' ? (
          <DatePicker format='DD/MM/YYYY' placeholder='Escreva sua resposta aqui' loading={loadingHistoric} autoFocus/>
        ) : question?.type === 'HORARIO' ? (
          <TimePicker format='HH:mm' placeholder='Escreva sua resposta aqui' loading={loadingHistoric} autoFocus/>
        ) : question?.type === 'NUMERICO' ? (
          <InputNumber prefix={question?.isMoneyFormat && 'R$'} placeholder='Escreva sua resposta aqui' precision={question?.decimalFormat || 0} loading={loadingHistoric} autoFocus/>
        ) : question?.type === 'ANEXO' ? (
          <Upload.Dragger
            folder={`Chamados/${InfoUsuario.get('cdCliente')}`}
            onChange={(info) => handleChangeAttachment(info)}
            multiple     
            URI={REACT_APP_API}
            headers={authorization()}
          />
        ) : null}
      </Form.Item>
      {question?.typeUpload !== 'NAO_PERMITE' && (
        <Form.Item name='upload' rules={question?.typeUpload === 'OBRIGATORIO' && [{ required: true, message: 'Upload obrigatório' }]} valuePropName='fileList' getValueFromEvent={getValueFromFile}>
          <Upload.Dragger
            folder={`Chamados/${InfoUsuario.get('cdCliente')}`}
            onChange={(info) => handleChangeAttachment(info)}
            multiple
          />
        </Form.Item>
      )}
      {question?.typeObservation !== 'NAO_PERMITE' && (question?.type === 'DATA' || question?.type === 'HORARIO' || question?.type === 'NUMERICO' || question?.type === 'ANEXO') && (
        <ExtraInfoContainer>
          <Column gap='15px'>
            <Column gap='5px'>
              <ExtraInfoLabel>
                <Row>
                  {question?.hintObservation || 'Observação'}
                  {question?.typeObservation === 'OBRIGATORIO' && (<span className='required-mark'>*</span>)}
                </Row>
                {/* {question?.hintObservation && (
                  <Row gap='6px' align='center'>
                    <HelpText>Ajuda</HelpText>
                    <Tooltip title={question?.hintObservation}>
                      <HelpIcon type='help-circle'/>
                    </Tooltip>
                  </Row>
                )} */}
              </ExtraInfoLabel>
              
            </Column>
            <Form.Item marginless form={form} name='observation' rules={question?.typeObservation === 'OBRIGATORIO' && [{ required: true, message: 'Observação obrigatória' }]} >
              <Input placeholder={question?.hintObservation || 'Observação'}/>
            </Form.Item>
          </Column>
        </ExtraInfoContainer>
      )}
    </Form>
  );
}