export const mapperToForm = (checklistTemplateConfiguration) => {
  if (!checklistTemplateConfiguration) return {};
  
  return {
    faixaPontuacao: checklistTemplateConfiguration.showWeight,
    risks: checklistTemplateConfiguration.risks.map((risk) => ({
      id: risk.id,
      previousPercentage: risk.previousPercentage !== 0 ? risk.previousPercentage : '0',
      percentage: risk.percentage,
      color: risk.color,
      description: risk.description,
    })),
  };
};