import React, { useEffect, useMemo, useState } from 'react';
import { ActivityStatus, AvatarStyle, BadgeInfo, RowStyle, CardLeftColumn, CardStyle, CardsCompleted, ContainerCheckbox, ContentActivityHistory, ContentButtonLimit, ContentCompleted, ContentDateDay, ContentNameChecklist, ContentPending, ContentQuestions, DateCompleted, DateDay, DayCompleted, DescriptionAnswer, DescriptionAtachmentAnswer, DescriptionQuestion, EventType, FeatherIcon, InfoIcon, LikeButton, PendingTitle, PerfilName, PerfilReponsability, PerfilRow, PhoneIcon, StyleEmpty, StyleRow, TextStyle, CallButton } from './styles';
import { AddressView } from '../styles';
import { Column, Row, Skeleton, Avatar, Button, Modal, Tooltip, Switch, Text, AudioPlayer } from '@components-teammove';
import { getDownloadUrlAvatar, moment, InfoUsuario, openPopup } from '@utils';
import { getPendingActivities, getCompleteActivities } from '@ducks/unidades';
import { useDispatch, useSelector } from 'react-redux';
import { getIcon } from './rules';
import { getFinishedChecklist } from '@ducks/checklists';
import ChecklistAnswers from '../../../GestaoEquipe/ActivitiesControl/UsersList/UserPage/Tasks/Task/ChecklistAnswers';
import { useHistory } from 'react-router-dom';
import ForWhoCalling from './ForWhoCallingModal';

export default function ActivityHistory({ user, handleOnClosePopUp, limit, setLimit }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const pendingActivities = useSelector(({ unidades }) => unidades.get('pendingActivities'));
  const loadingPendingActivities = useSelector(({ unidades }) => unidades.get('loadingPendingActivities'));

  const completeActivities = useSelector(({ unidades }) => unidades.get('completeActivities'));
  const loadingCompleteActivities = useSelector(({ unidades }) => unidades.get('loadingCompleteActivities'));
  const successCompleteActivities = useSelector(({ unidades }) => unidades.get('successCompleteActivities'));
  const successSave = useSelector(({ atividades }) => atividades.get('successSave'));

  const contacts = useSelector(({ contatos }) => contatos.get('contatos'));
  const unidade = useSelector(({ unidades }) => unidades.get('unidade'));
  
  const [visibleAttachment, setVisibleAttachment] = useState(false);
  const [uploads, setUploads] = useState([]);
  const [index, setIndex] = useState();
  const [visibleChecklist, setVisibleChecklist] = useState(false);
  const [activity, setActivity] = useState();
  const [visibleCanceledActivities, setVisibleCanceledActivities] = useState(false);
  const [visibleToCall, setVisibleToCall] = useState(false);
  const [activityId, setActivityId] = useState(null);
  const [activitiesLoaded, setActivitiesLoaded] = useState([]);

  const voipPermission = InfoUsuario.perm('voip', InfoUsuario.get());

  const infoForCallingModal = {
    identifier: unidade.identifier,
    companyName: unidade.name,
    companyId: unidade.id,
    telephoneUnity: unidade.telephone,
    contacts: contacts.filter((contato) => contato.telefone),
  };

  const visibleActivites  = useMemo(() => {
    if(successCompleteActivities && !visibleCanceledActivities) {
      const filteredActivities = completeActivities?.activities?.filter((activity) => activity.active);
      setActivitiesLoaded({ activities: filteredActivities });
      return { activities: filteredActivities };
    }
    if(successCompleteActivities && visibleCanceledActivities) {
      setActivitiesLoaded(completeActivities);
      return completeActivities;
    }
    return activitiesLoaded;
  }, [visibleCanceledActivities, successCompleteActivities]);

  useEffect(() => {
    if(unidade.id) {
      dispatch(getPendingActivities(unidade.id, user.cdUsuario, 'PENDENTE', 'ASC'));
    } 
  }, [unidade, successSave]);

  useEffect(() => { 
    if(unidade.id) {
      dispatch(getCompleteActivities(unidade.id, user.cdUsuario, 'FINALIZADA CANCELADA', 'DESC', limit));
    }
  }, [limit, unidade]);

  const handleChangeLimit = () => {
    setLimit(limit + 10);
  };

  const handleViewAttachment = (uploads, index) => {
    setIndex(index);
    setUploads(uploads);
    setVisibleAttachment(true);
  };

  const handleViewChecklist = (activity) => {
    setActivity(activity);
    
    if(!activity.active) {
      dispatch(getFinishedChecklist(activity.cancellationChecklistId));
    } else {
      dispatch(getFinishedChecklist(activity.checklistId));
    } 

    setVisibleChecklist(true);
  };

  const handlePrepareToCall = (number, name, companyCallId, contactCallId, isFromContact) => {
    const params = new URLSearchParams({
      number,
      name,
      CompanyId: companyCallId,
      activityId: activityId,
    });

    if (isFromContact) {
      params.append('ContactId', contactCallId);
    }
    const popupUrl = `${window.location.origin}/voip?${params.toString()}`;
    openPopup(popupUrl, '_blank', 400, 970, '/voip', handleOnClosePopUp);
  };

  const handleOpenModalCalling = (activityId) => {
    setActivityId(activityId);
    setVisibleToCall(true);
  };

  return(
    <>
      {(loadingCompleteActivities && loadingPendingActivities && limit === 20 ) ? (<Skeleton.LargeBox />
      ) : (
        <>
          <AddressView>
            {completeActivities?.activities?.length > 0 || pendingActivities?.activities?.length > 0 ? (
              <>
                <ContentActivityHistory>
                  {pendingActivities?.activities?.length > 0 && (
                    <>
                      <ContentPending key={pendingActivities.id}>
                        <PendingTitle>
                    Pendentes ({pendingActivities.total})
                        </PendingTitle>
                        {pendingActivities?.activities?.map((pendingActivity) => {
                          const isVoipActivity = pendingActivity.activityTypeName === 'Ligação' || pendingActivity.activityTypeName === 'Ligação Antiga';
                          return (
                            <CardStyle color={pendingActivity.color} key={pendingActivity.id}>
                              <Column gap='8px' width='100%'>
                                <PerfilRow>
                                  <AvatarStyle>
                                    <Avatar size={32} src={getDownloadUrlAvatar(pendingActivity.userPicture)} />
                                  </AvatarStyle>
                                  <PerfilName>
                                    {pendingActivity.userName}
                                  </PerfilName>                           
                                </PerfilRow>

                                <Column gap='4px'> 
                                  <ContentNameChecklist point={!pendingActivity.checklistId && 'default'}>
                                  
                                    <Row align='center' gap='8px'>
                                      <RowStyle 
                                        align='center'
                                        gap='4px'
                                      
                                        onClick={() => { 
                                          pendingActivity.id && history.push(`/atividades/agenda/${pendingActivity.id}`); 
                                        }}
                                      >
                                        {isVoipActivity && (
                                          <PhoneIcon type='phone' size='20px'/>
                                        )}
                                        <EventType >
                                          {pendingActivity.name}
                                        </EventType>
                                    
                                      </RowStyle>

                                      {voipPermission && isVoipActivity && pendingActivity?.recordUrl && (
                                        <InfoIcon
                                          type='info' size='20px'
                                          onClick={() => history.push(`/atividades/ligacoes/detalhes/${pendingActivity.voiceCallRecordId}`)}
                                        />
                                      )}
                                    </Row>

                                    {pendingActivity.checklistId && (
                                      <Button type='icon' transparent size='small' icon={(<FeatherIcon type='clipboard' size='18px' />)} onClick={() => { pendingActivity.checklistId && handleViewChecklist(pendingActivity); }}/>
                                    )}
                                    {voipPermission && pendingActivity.activityTypeName === 'Ligação' && !pendingActivity?.recordUrl && (
                                      <CallButton onClick={()=> handleOpenModalCalling(pendingActivity.id)}>
                                      Ligar
                                      </CallButton>
                                    )}
                                  </ContentNameChecklist>
                                  <PerfilReponsability>
                                    {pendingActivity.profileName}
                                  </PerfilReponsability>
                                </Column>

                                {InfoUsuario.perm('voip', InfoUsuario.get()) && isVoipActivity && pendingActivity?.recordUrl && (
                                  <Row gap='12px' width='100%' justify='space-between'>
                                    <Column width='90%'>
                                      <AudioPlayer src={pendingActivity?.recordUrl} bgColor='secondary' width='320px'/>
                                    </Column>
                                  
                                    <LikeButton
                                      color={pendingActivity.classification === 'Relevante' ? 'var(--success-color)' : pendingActivity.classification === 'Nao_Relevante' ? 'var(--danger-color)' : '#fff'}
                                    >
                                      <FeatherIcon 
                                        type={pendingActivity.classification === 'Relevante' ? 'thumbs-up' : pendingActivity.classification === 'Nao_Relevante' ? 'thumbs-down' : 'user-x'}
                                        color={pendingActivity.classification === 'Relevante' ? 'var(--success-color)' : pendingActivity.classification === 'Nao_Relevante' ? 'var(--danger-color)' : '#fff'}
                                      />
                                    </LikeButton>
                                  </Row>
                                )}

                                {pendingActivity?.notes && isVoipActivity && voipPermission && (
                                  <ContentQuestions>
                                    <DescriptionQuestion>Observação:</DescriptionQuestion>
                                    <DescriptionAnswer>{pendingActivity?.notes}</DescriptionAnswer>
                                  </ContentQuestions>
                                )}
                              </Column>

                              <ContentDateDay>
                                <DateDay>
                                  {moment(pendingActivity.end).format('ddd, DD/MMM')}
                                </DateDay>
                                {pendingActivity.statusDescription && (
                                  <ActivityStatus color={pendingActivity.statusColor}>
                                    {(parseInt(pendingActivity.statusDescription.match(/\d+/), 10) > 0) ? (
                            `atrasado ${pendingActivity.statusDescription}`
                                    ) : pendingActivity.statusDescription.includes('hoje') ? (
                                      'Hoje'
                                    ) : (
                                      'Amanhã'
                                    )}
                                  </ActivityStatus>
                                )}
                              </ContentDateDay>                           
                            </CardStyle>
                          );})};
                      </ContentPending>
                    </>
                  )}

                  {completeActivities?.activities?.length > 0 && (
                    <>
                      <ContentCompleted>
                        <CardsCompleted key={completeActivities.id}>
                          {visibleActivites.activities?.map((completeActivity) => {
                            const isVoipActivity = completeActivity.activityTypeName === 'Ligação' || completeActivity.activityTypeName === 'Ligação Voip';
                            return (
                              <>
                                <Row gap='4px'>
                                  <DateCompleted>{moment(completeActivity.end).format('DD/MM/YYYY')} </DateCompleted>
                                  <DayCompleted>- {moment(completeActivity.end).format('dddd')} (há {moment().diff(completeActivity.end, 'days')} dia{moment().diff(completeActivity.end, 'days') === 1 ? '' : 's'})</DayCompleted>
                                </Row>
                                <CardStyle concluded color={completeActivity.color}>
                                  <CardLeftColumn>
                                    <PerfilRow>
                                      <AvatarStyle>
                                        <Avatar size={32} src={getDownloadUrlAvatar(completeActivity.userPicture)} />
                                      </AvatarStyle>
                                      <PerfilName>
                                        {completeActivity.userName}
                                      </PerfilName>

                                    </PerfilRow>
                                    <Column gap='4px'>
                                      <ContentNameChecklist canceled={completeActivity.statusDescription}>
                                      
                                        <Row align='center' gap='8px'>
                                          <RowStyle 
                                            align='center'
                                            gap='4px'
                                            onClick={() => { 
                                              completeActivity.id && history.push(`/atividades/agenda/${completeActivity.id}`);
                                            }}
                                          >
                                            {isVoipActivity && (
                                              <PhoneIcon type='phone' size='20px'/>
                                            )}
                                            <EventType >
                                              {completeActivity.name}
                                            </EventType>
                                       
                                          </RowStyle>
                                          {isVoipActivity && InfoUsuario.perm('voip', InfoUsuario.get()) && completeActivity?.recordUrl && (
                                            <InfoIcon
                                              type='info' size='20px' 
                                              onClick={()=> history.push(`/atividades/ligacoes/detalhes/${completeActivity.voiceCallRecordId}`)}
                                            />
                                          )}
                                        </Row>
                                     
                                        {completeActivity.checklistId !== 0 && completeActivity.statusDescription !== 'cancelada' && (
                                          <Button type='icon' transparent size='small' icon={(<FeatherIcon type='clipboard' size='18px' onClick={() => { completeActivity.checklistId && handleViewChecklist(completeActivity); }} />)} />
                                        )}

                                        {completeActivity.statusDescription === 'cancelada' && completeActivity.cancellationChecklistId !== 0 && (
                                          <Button type='icon' transparent size='small' icon={(<FeatherIcon type='clipboard' size='18px' onClick={() => { completeActivity.cancellationChecklistId && handleViewChecklist(completeActivity); }} />)} />
                                        )}

                                        {(completeActivity.checklistId === 0 && completeActivity.observation) && (
                                          <Tooltip title={completeActivity.observation}>
                                            <BadgeInfo>Observação</BadgeInfo>
                                          </Tooltip>
                                        )}
                                      </ContentNameChecklist>
                                      <PerfilReponsability>
                                        {completeActivity.profileName}
                                      </PerfilReponsability>
                                    </Column>

                                    {InfoUsuario.perm('voip', InfoUsuario.get()) && isVoipActivity && completeActivity?.recordUrl && (
                                      <Row gap='12px' width='100%' justify='space-between'>
                                        <Column width='90%'>
                                          <AudioPlayer src={completeActivity?.recordUrl} bgColor='secondary' width='320px'/>
                                        </Column>
                                      
                                        < LikeButton
                                          color={completeActivity.classification === 'Relevante' ? 'var(--success-color)' : completeActivity.classification === 'Nao_Relevante' ? 'var(--danger-color)' : '#fff'}
                                        >
                                          <FeatherIcon 
                                            type={completeActivity.classification === 'Relevante' ? 'thumbs-up' : completeActivity.classification === 'Nao_Relevante' ? 'thumbs-down' : 'user-x'}
                                            color={completeActivity.classification === 'Relevante' ? 'var(--success-color)' : completeActivity.classification === 'Nao_Relevante' ? 'var(--danger-color)' : '#fff'}
                                          />
                                        </LikeButton>
                                      </Row>
                                    )}
                                  
                                    <StyleRow>
                                      {completeActivity?.notes && (
                                        <ContentQuestions>
                                          <DescriptionQuestion>Observação:</DescriptionQuestion>
                                          <DescriptionAnswer>{completeActivity?.notes}</DescriptionAnswer>
                                        </ContentQuestions>
                                      )}
                                    
                                      <ContentQuestions>
                                        {completeActivity.importantQuestions.map(
                                          ({ questionType, questionName, questionId, answers }) =>
                                            answers.length > 0 && questionType !== 'ANEXO' &&
                                    answers.some(({ uploads, value }) => uploads.length > 0 || value) && (
                                              <>
                                                <DescriptionQuestion>{questionName}:</DescriptionQuestion>
                                                {answers.map(({ value }, index) => (
                                                  <DescriptionAnswer key={value}>
                                                    {index < answers.length - 1 ? value + ', ' : value + ';'}
                                                  </DescriptionAnswer>
                                                ))}
                                              </>
                                            )
                                        )}
                                      </ContentQuestions>

                                      {completeActivity.importantQuestions.map(
                                        ({ questionType, questionName, questionId, answers }) =>
                                          answers.length > 0 && questionType === 'ANEXO' &&
                                  answers.some(({ uploads, value }) => uploads.length > 0 || value) && (
                                            <Row key={questionId} gap='4px'>
                                              <DescriptionQuestion>{questionName}:</DescriptionQuestion>
                                              {answers.map(({ uploads }) => (
                                                <>
                                                  {uploads.map((image, index) => (
                                                    <div key={image}>
                                                      <DescriptionAtachmentAnswer>
                                                        <Button
                                                          type='icon' transparent size='small'
                                                          icon={getIcon(image)}
                                                          onClick={() => handleViewAttachment(uploads.map((upload) => {
                                                            const formattedImage = getDownloadUrlAvatar(upload);
                                                            if (formattedImage.includes('&')) {
                                                              return formattedImage.substring(0, formattedImage.lastIndexOf('&'));
                                                            }
                                                            return formattedImage;
                                                          }), index)}
                                                        />
                                                      </DescriptionAtachmentAnswer>
                                                    </div>
                                                  ))}

                                                </>
                                              ))}
                                            </Row>
                                          )
                                      )}

                                    </StyleRow>

                                  </CardLeftColumn>

                                  <ContentDateDay canceled={completeActivity.statusDescription}>
                                    <DateDay>
                                      {moment(completeActivity.start).format('HH:mm')} - {moment(completeActivity.end).format('HH:mm')}
                                    </DateDay>
                                    <ActivityStatus color={completeActivity.statusColor}>
                                      <TextStyle> 
                                        {completeActivity.statusDescription}
                                      </TextStyle>
                                    </ActivityStatus>
                                  </ContentDateDay>
                                </CardStyle >
                              </>
                            );})}
                        </CardsCompleted>
                        {(!limit || limit < completeActivities?.total) && (
                          <ContentButtonLimit>
                            <Button loading={loadingCompleteActivities} type='secondary' size='small' onClick={() => handleChangeLimit()}>{'Carregar mais'}</Button>
                          </ContentButtonLimit>
                        )}

                      </ContentCompleted>
                    </>
                  )}
                </ContentActivityHistory>
             
              </>
            ) : (<StyleEmpty description='Sem Dados' />)}
          </AddressView >
          <ContainerCheckbox>
            <Text>Visualizar atividades canceladas</Text>
            <Switch checked={visibleCanceledActivities} onChange={(value) => setVisibleCanceledActivities(value)}/>          
          </ContainerCheckbox>
        </>
      )}
      {
        visibleAttachment && (
          <Modal.Picture
            visible={visibleAttachment}
            onCancel={() => setVisibleAttachment(false)}
            initialImageIndex={index}
            pictures={uploads}
            emptyText='Sem imagens'
            loading={loadingCompleteActivities}
          />
        )
      }
      {
        visibleChecklist && (
          <ChecklistAnswers
            visible={visibleChecklist}
            onCancel={() => setVisibleChecklist(false)}
            task={{ ...activity, companyCity: unidade.city, companyState: unidade.state }}
          />
        )
      }
      {
        visibleToCall && (
          <ForWhoCalling
            visible={visibleToCall}
            onCancel={() => setVisibleToCall(false)}
            handlePrepareToCall={handlePrepareToCall}
            infoForCallingModal={infoForCallingModal}
          />
        )
      }
    </>
  );
}
