/* eslint-disable camelcase */
import { getIdTemp } from '@utils';

export const initialValues = {
  titulo: '',
  descricao: '',
  status: 'LIBERADO',
  assinatura_digital: 'N',
  enviar_email: 'N',
  permite_comparacao: 'N',
  visao_consultor: 'N', 
  nome_laudo: '',
  acao_estrategica: 'N',
  nome_plano_acao: '',
};

export const formatOpcoesAnexo = (opcoesAnexo) => ({
  utiliza_camera: opcoesAnexo?.includes('camera'),
  utiliza_galeria: opcoesAnexo?.includes('galeria'),
  utiliza_audio: opcoesAnexo?.includes('audio'),
  utiliza_documento: opcoesAnexo?.includes('documento'),
});

export const mapperQuestionToForm = (question, isCopy) => {
  const {
    id,
    temp_id,
    titulo,
    opcional,
    tipo,
    observacao,
    ordem,
    opcoes = [],
    tags,
    tipo_observacao,
    tipo_upload,
    permissao_camera_galeria,
    utiliza_audio,
    utiliza_camera,
    utiliza_documento,
    utiliza_galeria,
    utiliza_ultima_resposta,
    intervalo_maximo,
    intervalo_minimo,
    peso,
    monetario,
    casas_decimal,
    importante,
  } = question;

  const formattedTags = tags ? tags.split(',') : [];
  const questionAttachments = [];
  if (utiliza_audio) questionAttachments.push('audio');
  if (utiliza_camera) questionAttachments.push('camera');
  if (utiliza_documento) questionAttachments.push('documento');
  if (utiliza_galeria) questionAttachments.push('galeria');

  const formattedOptions = opcoes.map((opcao, index) => {
    const {
      id,
      temp_id,
      valor,
      peso,
      anula_pergunta,
      enabled_questions = [],
      permissao_camera_galeria,
      tipo_observacao,
      tipo_upload,
      gerar_tarefa,
      tarefa_dica,
      observacao_dica,
      upload_dica,
      utiliza_audio,
      utiliza_camera,
      utiliza_documento,
      utiliza_galeria
    } = opcao;

    const optionsAttachments = [];
    if (utiliza_audio) optionsAttachments.push('audio');
    if (utiliza_camera) optionsAttachments.push('camera');
    if (utiliza_documento) optionsAttachments.push('documento');
    if (utiliza_galeria) optionsAttachments.push('galeria');

    return {
      [`id_opcao_${index}`]: isCopy ? null : id,
      [`temp_id_opcao_${index}`]: isCopy ? getIdTemp() : temp_id,
      [`valor_opcao_${index}`]: valor,
      [`peso_opcao_${index}`]: peso,
      [`anula_pergunta_opcao_${index}`]: anula_pergunta,
      [`enabled_questions_opcao_${index}`]: enabled_questions.map((e) => e.id || e.temp_id),
      [`upload_dica_opcao_${index}`]: upload_dica,
      [`tarefa_dica_opcao_${index}`]: tarefa_dica,
      [`gerar_tarefa_opcao_${index}`]: gerar_tarefa,
      [`tipo_observacao_opcao_${index}`]: tipo_observacao,
      [`observacao_dica_opcao_${index}`]: observacao_dica,
      [`permissao_camera_galeria_opcao_${index}`]: permissao_camera_galeria,
      [`tipo_upload_opcao_${index}`]: tipo_upload,
      [`opcoes_anexo_opcao_${index}`]: optionsAttachments,
    };
  }).reduce((acc, option) => ({ ...acc, ...option }), {});
  const form = {
    id: isCopy ? null : id,
    temp_id: isCopy ? getIdTemp() : temp_id,
    titulo: isCopy ? `${titulo} - Cópia` : titulo,
    opcional,
    tipo,
    observacao,
    ordem,
    tags: formattedTags,
    tipo_observacao,
    tipo_upload,
    permissao_camera_galeria,
    opcoes_anexo: questionAttachments,
    utiliza_ultima_resposta,
    intervalo_maximo,
    intervalo_minimo,
    peso,
    monetario,
    casas_decimal,
    importante,
    ...formattedOptions
  };

  const removeFieldsIfOptionsExist = (fields) => {
    const fieldsToRemove = [
      'monetario',
      'casas_decimal',
      'intervalo_maximo',
      'intervalo_minimo',
      'opcoes_anexo',
      'tipo_observacao',
      'permissao_camera_galeria',
      'tipo_upload',
    ];

    if (opcoes.length > 0) {
      fieldsToRemove.forEach((field) => delete fields[field]);
    }

    return fields;
  };

  return removeFieldsIfOptionsExist(form);
};

export const copyGroupsChecklistTemplate = (groups) => {
  const generateTempId = () => getIdTemp();
  const idMap = new Map();

  groups.forEach((group) => {
    idMap.set(group.id, generateTempId());

    group.perguntas.forEach((pergunta) => {
      idMap.set(pergunta.id, generateTempId());

      pergunta.opcoes.forEach((opcao) => {
        idMap.set(opcao.id, generateTempId());

        opcao.enabled_questions.forEach((enabled) => {
          if (enabled.id) {
            idMap.set(enabled.id, generateTempId());
          }
        });
      });
    });
  });

  const copiedGroups = groups.map((group) => {
    const groupTempId = idMap.get(group.id);

    const copiedPerguntas = group.perguntas.map((pergunta) => {
      const perguntaTempId = idMap.get(pergunta.id);

      const copiedOpcoes = pergunta.opcoes.map((opcao) => {
        const opcaoTempId = idMap.get(opcao.id);

        return {
          ...opcao,
          id: null,
          temp_id: opcaoTempId,
          enabled_questions: opcao.enabled_questions.map((enabled) => ({
            ...enabled,
            id: null,
            temp_id: idMap.get(enabled.id) || null,
          })),
        };
      });

      return {
        ...pergunta,
        id: null,
        temp_id: perguntaTempId,
        opcoes: copiedOpcoes,
        idPerguntaPai: idMap.get(pergunta.idPerguntaPai) || null,
        enabledQuestionId: perguntaTempId
      };
    });

    return {
      ...group,
      id: null,
      temp_id: groupTempId,
      perguntas: copiedPerguntas,
    };
  });

  return copiedGroups;
};