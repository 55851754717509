import { get, post } from '../api'; 
import { InfoUsuario } from '../../utils';

type PendingGeolocationCompanies = {
  total: number;
  companies: {
    id: number;
    name: string;
    city: string;
    state: string;
    shortName: string;
    pendingSince: Date;
    numberOfTries: number;
  }[];
  message?: string;
};
export const getPendingGeolocationCompanies = (): PendingGeolocationCompanies => {
  const response = get(`/api/companies/geolocation?userId=${InfoUsuario.get('cdUsuario')}`, {}, true);
  return response as unknown as PendingGeolocationCompanies;
};

export const updatePendingGeolocationCompanies = () => {
  return post(`/api/companies/geolocation?userId=${InfoUsuario.get('cdUsuario')}`, {}, {}, true);
};