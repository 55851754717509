/* eslint-disable camelcase */
import { Checkbox, Form, InputNumber, Row } from '@components-teammove';
import React from 'react';

const Numerico = ({ form }) => {

  const handleMonetarioChange = (checked) => {
    if (checked) {
      form.setFieldsValue({ casas_decimal: 2 });
    }
  };
  
  return (
    <>
      <Form.Item name='monetario' valuePropName='checked'>
        <Checkbox onChange={(value) => handleMonetarioChange(value)}>
          Monetário
        </Checkbox>
      </Form.Item>
      <Row width='100%' align='center' gap='12px'>
        <Form.Item name="casas_decimal" label="Nº Casas decimais">
          <InputNumber />
        </Form.Item>
        <Form.Item name="intervalo_minimo" label="Valor mínimo">
          <InputNumber />
        </Form.Item>
        <Form.Item name="intervalo_maximo" label="Valor máximo">
          <InputNumber />
        </Form.Item>
      </Row>
    </>
  );
};

export default Numerico;