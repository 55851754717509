import React, { forwardRef } from 'react';
import { NativeInputStyled } from './styles';

const NativeInput = forwardRef(({ placeholder, rows, onKeyUp, onKeyDown, disabled, ...props }, ref) => {
  const handleKeyDown = (event) => {
    if (onKeyDown) {
      onKeyDown(event);
    }

    setTimeout(() => {
      if (props.resizeChatInput) {
        props.resizeChatInput();
      }
    }, 0);
  };

  return (
    <NativeInputStyled
      placeholder={placeholder}
      rows={rows}
      onKeyUp={onKeyUp}
      onKeyDown={handleKeyDown}
      disabled={disabled}
      ref={ref}
      {...props}
    />
  );
});

export default NativeInput;
