import React, { useEffect, useState } from 'react';
import { Table, Icon, Column, SearchBar, Switch, Text, Row } from '@components-teammove';
import { useSelector } from 'react-redux';
import { reset } from '../../../../store/ducks/checklistTemplates';
import { useHistory } from 'react-router-dom';
import { LoadMoreButton } from '../../../../ui-teammove';
import { RowTag, TagStyled } from './styles';

export default function Templates({ filtros, search, handleSearch, limit, initialLimit, offset, orderBy }) {
  const history = useHistory();

  const checklistTemplates = useSelector(({ checklistTemplates }) => checklistTemplates.get('checklistTemplates'));
  const total = useSelector(({ checklistTemplates }) => checklistTemplates.get('total'));
  const loading = useSelector(({ checklistTemplates }) => checklistTemplates.get('loading'));
  const success = useSelector(({ checklistTemplates }) => checklistTemplates.get('success'));

  const [loadingMore, setLoadingMore] = useState(false);
  const [allTemplates, setAllTemplates] = useState(false);

  useEffect(() => {
    return () => reset();
  }, []);

  useEffect(() => {
    handleSearch({ ...filtros, search: '', allTemplates }, { limit: initialLimit, offset, orderBy });
  }, [allTemplates]);

  useEffect(() => {
    if (success) {
      setLoadingMore(false);
    }
  }, [success]);

  const handleLoadMoreClick = () => {
    handleSearch({ ...filtros, allTemplates }, { limit: limit + 50, offset, orderBy });
    setLoadingMore(true);
  };

  const renderStatus = (text) => {
    switch (text) {
      case 'LIBERADO':
        return <Text>Liberado</Text>;
      case 'DESATIVADO':
        return <Text>Desativado</Text>;
      default:
        return <Text>Em cadastro</Text>;
    }
  };

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      sorter: true,
      defaultSortOrder: orderBy && orderBy.field === 'name' ? orderBy.order + 'end' : false,
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      sorter: true,
      defaultSortOrder: orderBy && orderBy.field === 'description' ? orderBy.order + 'end' : false,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
      defaultSortOrder: orderBy && orderBy.field === 'status' ? orderBy.order + 'end' : false,
      render: (text) => renderStatus(text)
    },
    {
      title: 'N° de perguntas',
      dataIndex: 'totalQuestions',
      defaultSortOrder: orderBy && orderBy.field === 'totalQuestions' ? orderBy.order + 'end' : false,
    },
    {
      title: 'Atividades relacionadas',
      dataIndex: 'taskType',
      defaultSortOrder: orderBy && orderBy.field === 'relatedActivities' ? orderBy.order + 'end' : false,
      render: (taskType, item) => taskType.length > 0 && (
        <RowTag gap='4px'>
          {taskType.map(({ name, color }, index) => (
            <TagStyled key={name + color} color={color}>{name}</TagStyled>    
          )       
          )}
        </RowTag>
      )
    }
  ];

  return (
    <>
      <SearchBar
        placeholder="Pesquisar"
        initialValue={search}
        onSearch={(value) => handleSearch({ ...filtros, search: value, allTemplates }, { limit: initialLimit, offset, orderBy })}
      />
      <Column gap='12px'>
        <Table
          columns={columns}
          dataSource={checklistTemplates}
          loading={loading && !loadingMore}
          hidePagination
          rowKey='id'
          onRow={(record) => {
            return {
              onClick: () => history.push(`/configuracoes/checklists/cadastro/${record.id}`),
            };
          }}
          onChange={
            (pagination, filters, { field, order }) => handleSearch(
              { 
                ...filtros, 
                allTemplates
              }, 
              { 
                limit: initialLimit, 
                offset, 
                orderBy: order ? {
                  field,
                  order: order.replace('end', '')
                } : false
              }
            )
          }
        />
        {(!loading || loadingMore) && limit < total && (
          <Column>
            <LoadMoreButton 
              type='secondary' 
              icon={<Icon.ReloadOutlined />} 
              loading={loadingMore}
              onClick={() => handleLoadMoreClick()} 
            >
                  Carregar mais
            </LoadMoreButton>
          </Column>
        )}
        <Row gap='12px' margin='0 0 12px 0' justify='flex-end'>
          <Switch checked={allTemplates} onChange={(value) => setAllTemplates(value)}/>
          <Text>Ver todos</Text>
        </Row>
      </Column>
    </>
  );
}