import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Modal, Form, Input, Select, Skeleton, Row, Button, View, Icon } from '@components-teammove';
import { useDispatch, useSelector } from 'react-redux';
import { getCluster, resetSuccess, saveCluster, resetCluster, deleteCluster, moveCompanieCluster } from '@ducks/configuracoesUnidadesClusters';
import { ActivityIcon, ActivityName, AddClusterButton, ClusterColumns, ClusterColumnsTitle, HorizontalLine, IconWrapper, InactiveTag, NumberInput, QuestionActionButton, StyleSelect, Title, ViewContainer } from './styles';
import { getPerfis } from '@sdk/Perfil';
import { getDadosCadastro } from '@ducks/atividades';
import { TelasNomenclaturas } from '@utils';
import { getTiposAtividades } from '@ducks/configuracoesAtividadesTipos';
import { getTiposUnidades } from '@ducks/configuracoesUnidadesTiposUnidades';
import ModalMoverUnidades from './ModalMoverUnidades';

export default function ClustersCadastro({ visible, onCancel }) {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const grupoClusters = useSelector(({ configuracoesUnidadesClusters }) => configuracoesUnidadesClusters.get('grupoClusters'));
  const loading = useSelector(({ configuracoesUnidadesClusters }) => configuracoesUnidadesClusters.get('loading'));
  const success = useSelector(({ configuracoesUnidadesClusters }) => configuracoesUnidadesClusters.get('success'));

  const loadingSave = useSelector(({ configuracoesUnidadesClusters }) => configuracoesUnidadesClusters.get('loadingSave'));
  const successSave = useSelector(({ configuracoesUnidadesClusters }) => configuracoesUnidadesClusters.get('successSave'));
  const loadingDelete = useSelector(({ configuracoesUnidadesClusters }) => configuracoesUnidadesClusters.get('loadingDelete'));
  const successDelete = useSelector(({ configuracoesUnidadesClusters }) => configuracoesUnidadesClusters.get('successDelete'));

  const active = useSelector(({ configuracoesAtividadesTipos }) => configuracoesAtividadesTipos.get('active'));
  const inactive = useSelector(({ configuracoesAtividadesTipos }) => configuracoesAtividadesTipos.get('inactive'));
  const loadingTiposAtividades = useSelector(({ configuracoesAtividadesTipos }) => configuracoesAtividadesTipos.get('loadingTiposAtividades'));

  const companyTypes = useSelector(({ configuracoesUnidadesTiposUnidades }) => configuracoesUnidadesTiposUnidades.get('tiposUnidades'));
  const loadingCompany = useSelector(({ configuracoesUnidadesTiposUnidades }) => configuracoesUnidadesTiposUnidades.get('loading'));

  const [newClusters, setNewClusters] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [visibleConfirmExclusionModal, setVisibleConfirmExclusionModal] = useState(false);
  const [originalFieldsValue, setOriginalFieldsValue] = useState();
  const [idsToMove, setIdsToMove] = useState([]);
  const [clusterToRemove, setClusterToRemove] = useState(null);

  const agroupActivities = [...inactive, ...active];

  const id = location.state?.cluster.id;

  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  const clustersOptions  = useMemo(() => {
    return [
      ...clusters
        .filter((clusterFilter) => clusterFilter.id !== clusterToRemove.id)
        .map((clusterMap) => ({ label: clusterMap.nome, value: clusterMap.id })),
      { label: 'Sem cluster', value: -1 }
    ];
  }, [clusterToRemove]);

  useEffect(() => {
    if (id) {

      dispatch(getCluster(id));
    }
    dispatch(getDadosCadastro());
    dispatch(getTiposAtividades());
    dispatch(getTiposUnidades());

    return () => {
      dispatch(resetCluster());
      dispatch(resetSuccess());
      if (id) {
        history.goBack();
      }
    };
  }, []);

  useEffect(() => {
    if (success && id) {
      setClusters(grupoClusters.clusters);
      const clustersInfo = {};
      for (const cluster of grupoClusters.clusters) {
        const propriedadeNome = `nameClus${cluster.id}`;
        const propriedadeQtDias = `dayClus${cluster.id}`;
        const propriedadeQntCompanies = `qntCompanies${cluster.id}`;

        clustersInfo[propriedadeNome] = cluster.nome;
        clustersInfo[propriedadeQtDias] = cluster.qtDias;
        clustersInfo[propriedadeQntCompanies] = cluster.numberOfCompanies;
      }
      form.setFieldsValue({ ...grupoClusters, ...clustersInfo });
      setOriginalFieldsValue(form.getFieldsValue(true));
    }
  }, [success]);

  useEffect(() => {
    const activitiesCluster = {};
    for (const cluster of clusters) {
      const propriedadeQntCompanies = `qntCompanies${cluster.id}`;

      activitiesCluster[propriedadeQntCompanies] = cluster.numberOfCompanies;
    }
    form.setFieldsValue({ ...activitiesCluster });
  }, [clusters]);

  useEffect(() => {
    if (successSave || successDelete) {
      onCancel();
    }
  }, [successSave, successDelete]);

  const handleDelete = ({ id }) => {
    dispatch(deleteCluster(id));
  };

  const handleSalvar = () => {
    form.validateFields().then(async (values) => {
      let saveObj = {};
      const formValuesWithoutClusters = Object.keys(values)
        .filter((key) => !key.includes('nameClus') && !key.includes('dayClus') && !key.startsWith('qntCompanies'))
        .reduce((acc, cur) => ({ ...acc, [cur]: values[cur] }), {});

      const clustersToSend = Object.keys(values)
        .filter((key) => key.includes('nameClus') || key.includes('dayClus'))
        .reduce((acc, cur) => {
          const match = cur.match(/(name|day)Clus(\d+)/);
          if (match) {
            // eslint-disable-next-line no-unused-vars
            const [_, type, id] = match;
            const idValue = parseInt(id);
            const existingObj = acc.find((obj) => obj.id === idValue);
            existingObj
              ? Object.assign(existingObj, { id: idValue, [type === 'name' ? 'nome' : 'qtDias']: values[cur] })
              : acc.push({ id: idValue, [type === 'name' ? 'nome' : 'qtDias']: values[cur] });
          }
          return acc;
        }, []);

      const newClustersWithoutId = newClusters.filter((objeto) => objeto.nome !== '' && objeto.qtDias !== 0)
        .map(({ id, ...restoDoObjeto }) => restoDoObjeto);

      saveObj = {
        id: grupoClusters?.id,
        ...formValuesWithoutClusters,
        clusters: [...clustersToSend, ...newClustersWithoutId]
      };
      dispatch(saveCluster(saveObj));
      dispatch(moveCompanieCluster(idsToMove));
    });
  };

  const renderActivityTypeOption = (activityType, selectedValues) => {
    const isSelected = selectedValues.includes(activityType.id);
    return (
      <Row gap='5px' align='center'>
        <IconWrapper color={activityType.cor}>
          <ActivityIcon type={activityType.icone} size='18px' />
        </IconWrapper>
        <ActivityName>{activityType.nome}</ActivityName>
        {!activityType.ativo && !isSelected && <InactiveTag>Inativo</InactiveTag>}
      </Row>
    );
  };

  const addCluster = () => {
    setNewClusters([...newClusters, {
      id: newClusters.length + 1,
      nome: '',
      qtDias: 0
    }]);
  };

  const handleClusterName = (item, id, value) => {
    setNewClusters(newClusters.map((cluster) => (
      cluster.id === id ? { ...cluster, nome: value.target.value } : { ...cluster }
    )));
  };

  const handleClusterDay = (item, id, value) => {
    setNewClusters(newClusters.map((cluster) => (
      cluster.id === id ? { ...cluster, qtDias: value } : { ...cluster }
    )));
  };

  const onBeforeCancel = () => {
    if(originalFieldsValue) {
      const currentFormValues = form.getFieldsValue(true);
      const valuesChanged = !Object.entries(originalFieldsValue).every(
        ([key, value]) => currentFormValues[key] === value
      );
      if(valuesChanged) return valuesChanged;
      const numberOfClusterHaveChanged = grupoClusters?.clusters.length !== clusters.length || newClusters.length > 0;
      return numberOfClusterHaveChanged;
    } 
    return form.isFieldsTouched();  
  }; 

  const handleDeleteUniqueCluster = (cluster, isClusterNew) => {
    if (cluster.numberOfCompanies > 0) {
      setClusterToRemove(cluster);
      setVisibleConfirmExclusionModal(true); 
    } else {
      isClusterNew
        ? setNewClusters(newClusters.filter((newCluster) => newCluster.id !== cluster.id))
        : setClusters(clusters.filter((oldCluster) => oldCluster.id !== cluster.id));
    }
  };

  const handleCloseModalMoverUnidades = (newClusterId) => {             
    if(newClusterId) {             
      setIdsToMove([...idsToMove, { oldCluster: clusterToRemove.id, newCluster: newClusterId }]);
      setClusters(clusters.map((clusterMap) => {
        if (clusterMap.id === newClusterId) {
          return {
            ...clusterMap,
            numberOfCompanies: clusterMap.numberOfCompanies + clusterToRemove.numberOfCompanies
          };
        }
        return clusterMap;
      }).filter((clusterFilter) => clusterFilter.id !== clusterToRemove.id));
    }
    setVisibleConfirmExclusionModal(false);
  };

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title={'Configurações / ' + (companyNomenclature.plural) + ' / Clusters'}
      numberOfButtons={id ? 2 : 1}
      cancelText='Excluir'
      onBeforeCancel={onBeforeCancel}
      cancelButtonConfirm
      cancelButtonConfirmProps={{ title: 'Deseja realmente excluir este grupo de cluster?', onConfirm: () => handleDelete(grupoClusters) }}
      cancelButtonProps={{ loading: loadingDelete, type: 'danger', onClick: null, title: grupoClusters?.inUseByCompanies ? `Grupo em uso por ${(companyNomenclature.artigo) === 'a' ? 'uma' : 'um'} ou mais ${companyNomenclature.plural}` : 'Excluir grupo de cluster', disabled: grupoClusters?.inUseByCompanies }}
      onOk={handleSalvar}
      okButtonProps={{ loading: loadingSave }}
    >
      {(loading || loadingTiposAtividades || loadingCompany) ? <Skeleton.Form /> : (
        <>
          <Form gap='20px' layout='vertical' form={form}>
            <Form.Item label='Grupo de cluster' name='name' rules={[{ required: true, message: 'Informe o grupo de cluster!' }]} colon={false}>
              <Input placeholder='Informe o grupo de cluster' autoFocus />
            </Form.Item>

            <Form.Item tooltip='Informe o perfil de usuários responsáveis por realizar as atividades' label='Perfis responsáveis' name='assigneeProfiles' rules={[{ required: true, message: 'Informe os perfis responsável!' }]} colon={false}>
              <Select mode="multiple" placeholder='Selecione o perfil responsavel' action={getPerfis} />
            </Form.Item>

            <Form.Item tooltip='Informe o perfil de usuário que pode visualizar este cluster' label='Perfis visualização' name='visualizationProfiles' rules={[{ required: true, message: 'Selecione o perfil' }]} colon={false}>
              <Select mode="multiple" placeholder='Selecione o perfil' action={getPerfis} />
            </Form.Item>

            <Form.Item label='Tipos de atividade' name='typesOfActivities' rules={[{ required: true, message: 'Selecione os tipos de atividades do cluster' }]} colon={false}>
              <StyleSelect
                placeholder='Selecione os tipos de atividades do cluster'
                options={agroupActivities.map((activityType) => ({
                  label: renderActivityTypeOption(activityType, form.getFieldValue('typesOfActivities') || []),
                  title: activityType.nome,
                  value: activityType.id,
                  style: { opacity: activityType.ativo ? 1 : 0.5 },
                }))}
                loading={loadingTiposAtividades}
                mode="multiple"
              />
            </Form.Item>

            <Form.Item label='Tipos de unidade' name='companyKinds' rules={[{ required: true, message: 'Selecione os tipos de unidade do cluster' }]} colon={false}>
              <Select
                options={companyTypes?.map((companyType) => ({ label: companyType.name, title: companyType.name, value: companyType.id }))}
                placeholder='Selecione os tipos de unidade do cluster'
                loading={loadingCompany}
                mode="multiple"
              />
            </Form.Item>

            <HorizontalLine />
          </Form>

          <Title>Clusters</Title>
          <Form layout='vertical' form={form}>
            <Row gap='12px'>
               
              <ClusterColumns>
                <View>
                  {clusters?.length > 0 && (
                    <ClusterColumnsTitle>Nome do Cluster</ClusterColumnsTitle>
                  )}
                  {clusters?.map(({ id, nome }) => (
                    <Form.Item styles={{ width: '100%' }} key={id} name={'nameClus' + id} rules={[{ required: true, message: 'Digite o nome do cluster' }]} colon={false}>
                      <Input label='Nome do cluster' placeholder='Digite o nome do cluster' />
                    </Form.Item>
                  ))}
                </View>

                <View>
                  {clusters?.length > 0 && (
                    <ClusterColumnsTitle>Frequência</ClusterColumnsTitle>
                  )}
                  {clusters?.map(({ id, qtDias }) => (
                    <Form.Item key={id} name={'dayClus' + id} rules={[{ required: true, message: 'Digite o número de dias do cluster' }]} colon={false}>
                      <NumberInput label='Quantidade de dias' placeholder='Digite o nº de dias do cluster' />
                    </Form.Item>
                  ))}
                </View>
              
                <View>
                  {clusters?.length > 0 && (
                    <ClusterColumnsTitle>Qnt. de {(companyNomenclature.plural).toLowerCase()}</ClusterColumnsTitle>
                  )}
                  {clusters?.map(({ id }) => 
                    <Form.Item key={id} name={'qntCompanies' + id} colon={false}>
                      <NumberInput label='Unidades' disabled placeholder={form.getFieldValue(`qntCompanies${id}`) === 0 ? 0 : ''}/>
                    </Form.Item>
                  )}
                </View>

                <View>
                  {clusters?.length > 0 && (
                    <ClusterColumnsTitle textAlignCenter>Excluir</ClusterColumnsTitle>
                  )}
                  {clusters?.map((cluster) =>
                    <ViewContainer key={cluster.id}>
                      <QuestionActionButton type='secondary' size='small' icon={<Icon.DeleteOutlined />} title='Excluir' onClick={() => handleDeleteUniqueCluster(cluster, false)} />
                    </ViewContainer>
                  )}
                </View>
              </ClusterColumns>
            </Row>

            {newClusters.map((item, index) => (
              <Row key={item.id} gap='25px' >

                <ClusterColumns>
                  <View>
                    {!clusters.length > 0 && index === 0 && (
                      <ClusterColumnsTitle>Nome do Cluster</ClusterColumnsTitle>
                    )}
                    <Form.Item>
                      <Input value={item.nome} name='nomeNew' onChange={(value) => handleClusterName(item, item.id, value)} placeholder='Digite o nome do cluster' />
                    </Form.Item>
                  </View>
                  <View>
                    {!clusters.length > 0 && index === 0 && (
                      <ClusterColumnsTitle>Frequência</ClusterColumnsTitle>
                    )}
                    <Form.Item>
                      <NumberInput value={item.qtDias} name='qtDiasNew' onChange={(value) => handleClusterDay(item, item.id, value)} placeholder='Digite o nº de dias do cluster' />
                    </Form.Item>
                  </View>
                  <View>
                    {!clusters?.length > 0 && index === 0 && (
                      <ClusterColumnsTitle>Qnt. de {(companyNomenclature.plural).toLowerCase()}</ClusterColumnsTitle>
                    )}
                    <Form.Item key={id} name={'qntCompaniesNew' + id} colon={false}>
                      <NumberInput placeholder='0' label='Unidades' disabled/>
                    </Form.Item>
                  </View>
                  <View>
                    {!clusters?.length > 0 && index === 0 && (
                      <ClusterColumnsTitle textAlignCenter>Excluir</ClusterColumnsTitle>
                    )}
                    <ViewContainer key={id}>
                      <QuestionActionButton type='secondary' size='small' icon={<Icon.DeleteOutlined />} title='Excluir' onClick={() => handleDeleteUniqueCluster(item, true)} />
                    </ViewContainer>
                  </View>
                </ClusterColumns>
              </Row>
            ))}

            <AddClusterButton>
              <Button fillwidth type='secondary' onClick={addCluster}><Icon.Feather type='plus' />Adicionar cluster</Button>
            </AddClusterButton>
            <HorizontalLine top='20px' />

          </Form>
          {visibleConfirmExclusionModal && (
            <ModalMoverUnidades
              clustersOptions ={clustersOptions}
              clusterToRemove={clusterToRemove}
              visibleConfirmExclusionModal={visibleConfirmExclusionModal}
              onClose={handleCloseModalMoverUnidades}
            />
          )}
        </>
      )}
    </Modal>
  );
}