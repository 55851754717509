import { Button, Column, Icon, Row, Text, Menu } from '@components-teammove';
import { darken } from 'polished';
import styled from 'styled-components';

export const ColumnContainer = styled(Column)`
    width: 100%;
    max-width: 860px;
`;

export const EditIcon = styled(Icon.Feather)`
    color: ${({ theme }) => theme.textColor};
    cursor: pointer;
`;

export const RowContainerTitle = styled(Row)`
    margin-bottom: 20px;
`;

export const ContainerAsk = styled(Column)`
    border: 1px solid ${({ theme }) => theme.darkHighlightColor};
    border-radius: 8px;
    width: 100%;
    padding: 12px;
    background-color: ${({ theme }) => theme.darkPrimaryColor};
    gap: 8px;
    width: ${({ isConditional }) => isConditional ? 'calc(100% - 22px)' : '100%'};
    margin-left: ${({ isConditional }) => isConditional ? '22px' : '0'};
    margin-top: 20px;
    margin-bottom: ${({ nextQuestionIsConditional, differentParentThanPreviousQuestion }) => nextQuestionIsConditional && differentParentThanPreviousQuestion ? '20px' : '0'};
    cursor: pointer;
`;

export const ContainerConditional = styled.div`
    border-radius: 8px;
    padding: 3px 8px;
    background-color: ${({ theme }) => theme.darkHighlightColor};
    margin-left: 12px;
    width: fit-content;
    margin-top: ${({ hasMargin }) => hasMargin ? '20px' : '0'};
`;

export const ConditionalText = styled(Text)`
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.textColor};
`;

export const TitleAsk = styled(Text)`
    font-size: 16px;
    color: ${({ theme }) => theme.textColor};
    font-weight: ${({ title }) => title ? 400 : 700};
`;

export const TypeAsk = styled(Text)`
    font-size: 12px;
    color: ${({ theme }) => theme.textColor};
    font-weight: 700;
    opacity: 0.5;
`;

export const ColumnStyled = styled(Column)`
    position: relative;
`;

export const AbsoluteDiv = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.darkHighlightColor};
  width: 5px;
  height: 100%;
  height: ${({ hasMargin }) => hasMargin ? 90 : 100}%;
  margin-top: ${({ hasMargin }) => hasMargin ? '20px' : '0'};
`;

export const IconTypeFeather = styled(Icon.Feather)`
    color: ${({ theme }) => theme.textColor};
    font-size: 16px;
    width: 16px;
    height: 16px;
    opacity: 0.5;
`;

export const IconTypeMdi = styled(Icon.MDI)`
    color: ${({ theme }) => theme.textColor};
    font-size: 16px;
    width: 16px;
    height: 16px;
    opacity: 0.5;
`;

export const AddAskButton = styled(Button)`
    margin-top: 20px;
`;

export const HeaderGroupColored = styled.div`
    width: 100%;
    height: 4px;
    background-color: ${({ theme, firstGroup }) => firstGroup ? theme.foregroundColor : theme.actionColor};
`;

export const CountingGroupHeader = styled.div`
    background-color: ${({ theme }) => theme.actionColor};
    padding: 8px 12px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    color: ${({ theme }) => theme.textColor};
    font-weight: 700;
    font-size: 16px;
    width: max-content;
`;

export const ContainerWeight = styled.div`
    background-color: ${({ theme, headerWeightInfo }) => headerWeightInfo ? theme.darkPrimaryColor : theme.darkSecondaryColor};
    border-radius: 27px;
    padding: 6px 12px;
`;

export const WeightText = styled(Text)`
    font-size: 14px;
    color: ${({ theme }) => theme.textColor};
    font-weight: 400;
`;

export const DeleteIcon = styled(Icon.Feather)`
    color: ${({ theme }) => theme.textColor};
    cursor: pointer;
    opacity: 0.5;
    :hover {
        opacity: 1;
    }
    margin-bottom: 8px;
`;

export const TrashIcon = styled(Icon.Feather)`
    color: ${({ theme }) => theme.textColor};
    cursor: pointer;
    opacity: 0.5;
    :hover {
        opacity: 1;
    }
    width: 20px;
`;

export const OrderIcon = styled(Icon.Feather)`
    color: ${({ theme }) => theme.textColor};
    cursor: pointer;
    opacity: 0.5;
    :hover {
        opacity: 1;
    }
`;

export const QuestionActionButton = styled(Button)`
  &.ant-btn {
    padding: 10px;
  }
`;

export const MenuButton = styled(Button)`
  font-size: 14px !important;
`;

export const ContainerButton = styled(Menu.Item)`
  &.ant-dropdown-menu-item {
    color: ${(props) => props.theme.textColor} !important;

    .ant-btn {
      color: ${(props) => props.theme.textColor} !important;
      opacity: 1;

      :hover {
        background-color: transparent !important;
        opacity: 1;
      }
    }

    :hover {
      background-color: transparent;
    }
  }
`;

export const HoverableContainer  = styled.div`
  background-color: var(--danger-color);
  border-radius: 4px;
  padding-left: 3px;
  :hover {
    background-color: ${darken(0.085, '#FF3B30')} !important;
  }
`;