import { call, put, takeLatest } from 'redux-saga/effects';
import { Map, List } from 'immutable';
import { Notification } from '@utils';
import { getContactListByCompany as getContatosSdk, getContactById as getContatoSdk, createContact as postContatoSdk, updateContact as updateContatoSdk, deleteContact as deleteContatoSdk } from '@sdk/Contatos';

export const FILTERS_KEY = 'FILTROS/CONTATOS';

//Action Types
export const Types = {
  GET_CONTATOS: 'CONTATOS/GET_CONTATOS',
  GET_CONTATOS_SUCCESS: 'CONTATOS/GET_CONTATOS_SUCCESS',
  GET_CONTATOS_ERROR: 'CONTATOS/GET_CONTATOS_ERROR',

  POST_CONTATO: 'CONTATOS/POST_CONTATO',
  POST_CONTATO_SUCCESS: 'CONTATOS/POST_CONTATO_SUCCESS',
  POST_CONTATO_ERROR: 'CONTATOS/POST_CONTATO_ERROR',

  UPDATE_CONTATO: 'CONTATOS/UPDATE_CONTATO',
  UPDATE_CONTATO_SUCCESS: 'CONTATOS/UPDATE_CONTATO_SUCCESS',
  UPDATE_CONTATO_ERROR: 'CONTATOS/UPDATE_CONTATO_ERROR',

  DELETE_CONTATO: 'CONTATOS/DELETE_CONTATO',
  DELETE_CONTATO_SUCCESS: 'CONTATOS/DELETE_CONTATO_SUCCESS',
  DELETE_CONTATO_ERROR: 'CONTATOS/DELETE_CONTATO_ERROR',

  GET_CONTATO: 'CONTATOS/GET_CONTATO',
  GET_CONTATO_SUCCESS: 'CONTATOS/GET_CONTATO_SUCCESS',
  GET_CONTATO_ERROR: 'CONTATOS/GET_CONTATO_ERROR',

  RESET_CONTATO: 'CONTATOS/RESET_CONTATO',
  RESET: 'CONTATOS/RESET',
};

//Action Creators
export const getContatos = (idUnidade) => ({ type: Types.GET_CONTATOS, idUnidade });
export const getContatosSuccess = (contatos) => ({ type: Types.GET_CONTATOS_SUCCESS, contatos });
export const getContatosError = (error) => ({ type: Types.GET_CONTATOS_ERROR, error }); 

export const postContato = (contato) => ({ type: Types.POST_CONTATO, contato });
export const postContatoSuccess = (contato) => ({ type: Types.POST_CONTATO_SUCCESS, contato });
export const postContatoError = (error) => ({ type: Types.POST_CONTATO_ERROR, error });

export const updateContato = (contato) => ({ type: Types.UPDATE_CONTATO, contato });
export const updateContatoSuccess = (contato) => ({ type: Types.UPDATE_CONTATO_SUCCESS, contato });
export const updateContatoError = (error) => ({ type: Types.UPDATE_CONTATO_ERROR, error }); 

export const deleteContato = (id) => ({ type: Types.DELETE_CONTATO, id });
export const deleteContatoSuccess = (contato) => ({ type: Types.DELETE_CONTATO_SUCCESS, contato });
export const deleteContatoError = (error) => ({ type: Types.DELETE_CONTATO_ERROR, error }); 

export const getContato = (id) => ({ type: Types.GET_CONTATO, id });
export const getContatoSuccess = (contato) => ({ type: Types.GET_CONTATO_SUCCESS, contato });
export const getContatoError = (error) => ({ type: Types.GET_CONTATO_ERROR, error });

export const resetContato = () => ({ type: Types.RESET_CONTATO });
export const reset = () => ({ type: Types.RESET });

//saga
function* fetchGetContatos(action) {
  try {
    const { idUnidade } = action;
    const contatos = yield call(getContatosSdk, idUnidade);
    yield put(getContatosSuccess(contatos));
  } catch (err) {
    Notification.error(err.message);
    yield put(getContatosError(err));
  }
}

function* fetchGetContato(action) {
  try {
    const { id } = action;
    const contato = yield call(getContatoSdk, id);
    yield put(getContatoSuccess(contato));
  } catch (err) {
    Notification.error(err.message);
    yield put(getContatoError(err));
  }
}

function* fetchPostContato(action) {
  try {
    let { contato } = action;

    contato = yield call(postContatoSdk, contato);
    yield put(postContatoSuccess(contato));
  } catch (err) {
    Notification.error(err.message);
    yield put(postContatoError(err));
  }
}

function* fetchUpdateContato(action) {
  try {
    let { contato } = action;

    contato = yield call(updateContatoSdk, contato);
    yield put(updateContatoSuccess(contato));
  } catch (err) {
    Notification.error(err.message);
    yield put(updateContatoError(err));
  }
}

function* fetchDeleteContato(action) {
  try {
    const { id } = action;
    const contato = yield call(deleteContatoSdk, id);
    yield put(deleteContatoSuccess(contato));
  } catch (err) {
    Notification.error(err.message);
    yield put(deleteContatoError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_CONTATOS, fetchGetContatos),
  takeLatest(Types.GET_CONTATO, fetchGetContato),
  takeLatest(Types.POST_CONTATO, fetchPostContato),
  takeLatest(Types.UPDATE_CONTATO, fetchUpdateContato),
  takeLatest(Types.DELETE_CONTATO, fetchDeleteContato),
];

// Reducer
const initialState = Map({
  contatos: List(),
  loadingContatos: false,
  successContatos: false,
  errorContatos: false,
  contato: Map(),
  loading: false,
  success: false,
  error: false,
  loadingSave: false,
  successSave: false,
  errorSave: false,
  loadingDelete: false,
  successDelete: false,
  errorDelete: false
});

const handleGetContatos = (state, action) => {
  return state.set('loadingContatos', true).set('successContatos', false).set('errorContatos', false);
};

const handleGetContatosSuccess = (state, action) => {
  const { contatos } = action;

  return state.set('contatos', contatos).set('loadingContatos', false).set('successContatos', true).set('errorContatos', false);
};

const handleGetContatosError = (state, action) => {
  return state.set('loadingContatos', false).set('successContatos', false).set('errorContatos', action.error);
};

const handleGetContato = (state, action) => {
  return state.set('loading', true).set('success', false).set('error', false);
};

const handleGetContatoSuccess = (state, action) => {
  const { contato } = action;

  return state.set('contato', contato).set('loading', false).set('success', true).set('error', false);
};

const handleGetContatoError = (state, action) => {
  return state.set('loading', false).set('success', false).set('error', action.error);
};

const handleSaveContato = (state, action) => {
  return state.set('loadingSave', true).set('successSave', false).set('errorSave', false);
};

const handleSaveContatoSuccess = (state, action) => {
  return state.set('loadingSave', false).set('successSave', true).set('errorSave', false);
};

const handleSaveContatoError = (state, action) => {
  return state.set('loadingSave', false).set('successSave', false).set('errorSave', action.error);
};

const handleDeleteContato = (state, action) => {
  return state.set('loadingDelete', true).set('successDelete', false).set('errorDelete', false);
};

const handleDeleteContatoSuccess = (state, action) => {
  return state.set('loadingDelete', false).set('successDelete', true).set('errorDelete', false);
};

const handleDeleteContatoError = (state, action) => {
  return state.set('loadingDelete', false).set('successDelete', false).set('errorDelete', action.error);
};

const handleResetContato = (state, action) => {
  return state
    .set('contato', initialState.get('contato'))
    .set('success', initialState.get('success'))
    .set('successSave', initialState.get('successSave'))
    .set('successDelete', initialState.get('successDelete'));
};

const handleReset = (state, action) => {
  return initialState;
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_CONTATOS:
      return handleGetContatos(state, action);
    case Types.GET_CONTATOS_SUCCESS:
      return handleGetContatosSuccess(state, action);
    case Types.GET_CONTATOS_ERROR:
      return handleGetContatosError(state, action);
    case Types.GET_CONTATO:
      return handleGetContato(state, action);
    case Types.GET_CONTATO_SUCCESS:
      return handleGetContatoSuccess(state, action);
    case Types.GET_CONTATO_ERROR:
      return handleGetContatoError(state, action);
    case Types.POST_CONTATO:
      return handleSaveContato(state, action);
    case Types.POST_CONTATO_SUCCESS:
      return handleSaveContatoSuccess(state, action);
    case Types.POST_CONTATO_ERROR:
      return handleSaveContatoError(state, action);
    case Types.UPDATE_CONTATO:
      return handleSaveContato(state, action);
    case Types.UPDATE_CONTATO_SUCCESS:
      return handleSaveContatoSuccess(state, action);
    case Types.UPDATE_CONTATO_ERROR:
      return handleSaveContatoError(state, action);
    case Types.DELETE_CONTATO:
      return handleDeleteContato(state, action);
    case Types.DELETE_CONTATO_SUCCESS:
      return handleDeleteContatoSuccess(state, action);
    case Types.DELETE_CONTATO_ERROR:
      return handleDeleteContatoError(state, action);

    case Types.RESET_CONTATO:
      return handleResetContato(state, action);
    case Types.RESET:
      return handleReset(state, action);

    default:
      return state;
  }
}