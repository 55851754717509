/* eslint-disable camelcase */
import { Checkbox, Column, Select } from '@components-teammove';
import React, { useEffect } from 'react';
import { FormItemDynamic } from '../styles';
import { optionsAttachments, optionsConfigurationAttachment } from './rules';

const AnexosPergunta = ({ anexosOpcao, optionIndex = -1, form }) => {

  useEffect(() => {
    const isEditing = form.getFieldValue('temp_id') || form.getFieldValue('id');

    if (!isEditing && anexosOpcao[optionIndex]) {
      form.setFieldsValue({
        [(optionIndex !== -1)
          ? `permissao_camera_galeria_opcao_${optionIndex}`
          : 'permissao_camera_galeria']: 'I',
      });
    }
  }, [form, optionIndex, anexosOpcao]);

  return (
    <Column>
      <FormItemDynamic hasToRender={anexosOpcao[optionIndex]} label="Anexos por:" name={(optionIndex !== -1) ? `opcoes_anexo_opcao_${optionIndex}` : 'opcoes_anexo'}>
        <Checkbox.Group name="opcoes_anexo">
          {optionsAttachments.map(({ value, label }) => (
            <Checkbox key={value} value={value}>{label}</Checkbox>
          ))}
        </Checkbox.Group>
      </FormItemDynamic>
      <FormItemDynamic hasToRender={anexosOpcao[optionIndex]} name={(optionIndex !== -1) ? `permissao_camera_galeria_opcao_${optionIndex}` : 'permissao_camera_galeria'} label="Configuração Câmera/Galeria">
        <Select placeholder='Selecione uma opção' options={optionsConfigurationAttachment} />
      </FormItemDynamic>
    </Column>
  );
};

export default AnexosPergunta;