import styled from 'styled-components';
import { Menu, Dropdown, Text  } from '@components';
import { Icon } from '@components-teammove';
import { Link } from 'react-router-dom';

export const DropdownStyled = styled(Dropdown)`
  cursor: pointer;
  align-items: center;
`;

export const DownIcon = styled(Icon.DownOutlined)`
  font-size: 1em !important;
  margin-left: 5px;
`;

export const InfoItem = styled(Menu.Item)`
  cursor: text !important;
  display: flex;
  flex-direction: column;

  span[name*='nome'] {
    font-weight: bold;
  }
  span[name*='small'] {
    color: rgba(0, 0, 0, 0.25);
    font-size: small;
  }
  padding:0;
  :hover{
    background-color: unset !important;
  }
`;

export const AvatarMenu = styled(Menu)`
  background-color: ${({ theme }) => theme.foregroundColor} !important;
  width:279px;
  padding: 15px 20px 20px 20px;
  border-radius:18px !important;
`;

export const TextName = styled(Text)`
  color: ${({ theme }) => theme.textForegroundColor} !important;
`;

export const TextSmall = styled(Text)`
  color: ${({ theme }) => theme.textForegroundColor} !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: .5;
`;

export const Divider = styled(Menu.Divider)`
  border: 2px;
  background-color: ${({ theme }) => theme.darkPrimaryColor} !important;
  margin: 10px 0;
`;

export const MenuItem = styled(Menu.Item)`
  padding:0;
  :hover{
    background-color: unset !important;
  }
  margin: 0 10px;
`;

export const LinkText = styled(Link)`
  color: ${({ theme }) => theme.textForegroundColor} !important;
  :hover{
    background-color: ${({ theme }) => theme.darkPrimaryColor} !important;
    border-radius: 4px;
  }
`;

export const MenuExit = styled(Menu.Item)`
  padding:0;
  :hover{
    background-color: unset !important;
  }
  margin: 0 10px;
`;

export const LinkExit = styled(Link)`
  color: ${({ theme }) => theme.textForegroundColor} !important;
  border-radius: 4px;
  :hover {
    background-color: ${({ theme }) => theme.darkPrimaryColor} !important;
  }
`;