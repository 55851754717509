import React, { useEffect, useState } from 'react';
import App from '@app';
import { Body, PageHeader, Breadcrumb, Button, Icon, Filter, Table, Text, Column, Row, Grid, SearchBar, Switch, StatusDotIndicator, Skeleton, Progress } from '@components-teammove';
import { useDispatch, useSelector } from 'react-redux';
import { TelasNomenclaturas, Filtros, formatCnpj, formatCurrency, InfoUsuario } from '@utils';
import { FILTERS_KEY , getAdimplenciaList, reset , getAdimplenciaIndicadores , getAdimplenciaTotais } from '@ducks/companyDelinquency';
import { getOrderByFieldFromColumnIndex, initialFilters } from './rules';
import { getAgrupadores } from '@ducks/configuracoesUnidadesAgrupadores';
import { getTiposUnidades } from '@ducks/configuracoesUnidadesTiposUnidades';
import { IndicatorCard, LoadMoreButton, SwitchLabel } from './styles';
import { useHistory } from 'react-router-dom';

export default function Adimplencia() {
  const dispatch = useDispatch();

  const history = useHistory();

  const theme = useSelector(({ app }) => app.get('theme'));

  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  const initialLimit = 50;
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [filtros, setFiltros] = useState(Filtros.get(FILTERS_KEY));
  const [filters, setFilters] = useState(initialFilters);
  const [limit, setLimit] = useState(initialLimit);
  const [offset] = useState(0); // TODO: use this for pagination
  const [search, setSearch] = useState('');
  const [orderBy, setOrderBy] = useState();

  const [loadingMore, setLoadingMore] = useState(false);

  const delinquencyList = useSelector(({ companyDelinquency }) => companyDelinquency.get('adimplenciaList'));
  const delinquencyListTotal = useSelector(({ companyDelinquency }) => companyDelinquency.get('adimplenciaListTotal'));
  const loadingDelinquencyList = useSelector(({ companyDelinquency }) => companyDelinquency.get('loadingAdimplenciaList'));
  const successDelinquencyList = useSelector(({ companyDelinquency }) => companyDelinquency.get('successAdimplenciaList'));

  const delinquencyIndicators = useSelector(({ companyDelinquency }) => companyDelinquency.get('adimplenciaIndicadores'));
  const loadingDelinquencyIndicators = useSelector(({ companyDelinquency }) => companyDelinquency.get('loadingAdimplenciaIndicadores'));
  const delinquencyTotals = useSelector(({ companyDelinquency }) => companyDelinquency.get('adimplenciaTotais'));
  const loadingDelinquencyTotals = useSelector(({ companyDelinquency }) => companyDelinquency.get('loadingAdimplenciaTotais'));

  const agrupadores = useSelector(({ configuracoesUnidadesAgrupadores }) => configuracoesUnidadesAgrupadores.get('agrupadores'));
  const successAgrupadores = useSelector(({ configuracoesUnidadesAgrupadores }) => configuracoesUnidadesAgrupadores.get('successAgrupadores'));
  const tiposUnidades = useSelector(({ configuracoesUnidadesTiposUnidades }) => configuracoesUnidadesTiposUnidades.get('tiposUnidades'));
  const successTiposUnidades = useSelector(({ configuracoesUnidadesTiposUnidades }) => configuracoesUnidadesTiposUnidades.get('successTiposUnidades'));

  useEffect(() => {
    handleSearch(filtros, { limit, offset });

    dispatch(getAgrupadores());
    dispatch(getTiposUnidades());

    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    if ((successAgrupadores && successTiposUnidades)) {
      setFilters(filters.map((filter) => {
        if (filter.name === 'companyGroups') {
          return { ...filter, options: agrupadores.map((agrupador) => ({ label: agrupador.description, value: agrupador.id })) };
        }
        if (filter.name === 'companyKinds') {
          return { ...filter, options: tiposUnidades.map((tipoUnidade) => ({ label: tipoUnidade.name, value: tipoUnidade.id })) };
        }
        return { ...filter };
      }));
    }
  }, [successAgrupadores, successTiposUnidades]);

  useEffect(() => {
    if (successDelinquencyList) {
      setLoadingMore(false);
    }
  }, [successDelinquencyList]);

  const handleSearch = (filters, loaders, searchListOnly) => {
    Filtros.set(FILTERS_KEY, filters);
    setFiltros(filters);
    setLimit(loaders.limit);
    setSearch(filters.search || '');
    setOrderBy(loaders.orderBy);

    const filtersWithoutDelinquencyStatus = { ...filters, delinquencyStatus: undefined };

    dispatch(getAdimplenciaList({ 
      ...filters, 
      ...loaders 
    }));
    if (!searchListOnly) {
      dispatch(getAdimplenciaIndicadores({
        ...filtersWithoutDelinquencyStatus, ...loaders
      }));
      dispatch(getAdimplenciaTotais({
        ...filtersWithoutDelinquencyStatus, 
        ...loaders
      }));
    }
  };

  const handleLoadMoreClick = () => {
    handleSearch(filtros, { limit: limit + 50, offset });
    setLoadingMore(true);
  };

  const columns = [
    {
      title: 'Identificador',
      dataIndex: 'shortName',
      sorter: true,
      defaultSortOrder: orderBy && orderBy.field === 'identifier' ? orderBy.order + 'end' : false,
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      sorter: true,
      defaultSortOrder: orderBy && orderBy.field === 'name' ? orderBy.order + 'end' : false,
      render: (text, record) => (
        <Column gap='4px'>
          <Text size='16px' weight='400'>{record.name}</Text>
          <Column>
            <Text size='14px' weight='700' lowOpacity>{record.state} - {record.city} - {record.cep}</Text>
            <Text size='14px' weight='400' lowOpacity>{record.address}</Text>
          </Column>
        </Column>
      ),
    },
    {
      title: 'CNPJ',
      dataIndex: 'cnpj',
      sorter: true,
      defaultSortOrder: orderBy && orderBy.field === 'cnpj' ? orderBy.order + 'end' : false,
      render: (text, record) => (
        <Text>{formatCnpj(record.cnpj)}</Text>
      ),
    },
    {
      title: 'Agrupador',
      dataIndex: 'companyGroup',
      sorter: true,
      defaultSortOrder: orderBy && orderBy.field === 'companyGroup' ? orderBy.order + 'end' : false,
      render: (text, record) => (
        <Text>{record.companyGroup.name}</Text>
      ),
    },
    {
      title: 'Vencidos',
      key: 'expired',
      defaultSortOrder: orderBy && orderBy.field === 'delinquency' ? orderBy.order + 'end' : false,
      render: (text, record) => {
        const hasAnyTitle = record.delinquency.numberOfExpiredTitles > 0;

        return hasAnyTitle ? (
          <Row gap='12px' align='center'>
            <StatusDotIndicator status='danger'/>
            <Column gap='4px'>
              <Text size='16px' weight='700'>{formatCurrency(record.delinquency.expiredTitlesTotal)}</Text>
              <Text size='14px' weight='400' lowOpacity>{record.delinquency.numberOfExpiredTitles} título{record.delinquency.numberOfExpiredTitles > 1 && 's'}</Text>
            </Column>
          </Row>
        ) : null;
      },
    },
    {
      title: 'A Vencer',
      key: 'unexpired',
      defaultSortOrder: orderBy && orderBy.field === 'delinquency' ? orderBy.order + 'end' : false,
      render: (text, record) => {
        const hasAnyTitle = record.delinquency.numberOfUnexpiredTitles > 0;

        return hasAnyTitle ? (
          <Row gap='12px' align='center'>
            <StatusDotIndicator status='success'/>
            <Column gap='4px'>
              <Text size='16px' weight='700'>{formatCurrency(record.delinquency.unexpiredTitlesTotal)}</Text>
              <Text size='14px' weight='400' lowOpacity>{record.delinquency.numberOfUnexpiredTitles} título{record.delinquency.numberOfUnexpiredTitles > 1 && 's'}</Text>
            </Column>
          </Row>
        ) : null;
      },
    },
  ];

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader 
          title="Posição Financeira"
          breadcrumb={(
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: '/posicao-financeira', name: 'Posição Financeira', current: true }
              ]}
            />
          )}
          extra={[
            <Button size='small' context='header' key="1" type='secondary' onClick={() => setFiltersVisible(true)}>Filtros</Button>,
            <Button 
              size='small' 
              context='header' 
              visible={InfoUsuario.perm('unidadesConfiguracoesRegras')}
              key="1" 
              type='primary' 
              onClick={() => history.push('/configuracoes/indicadores?initialTab=Posição Financeira')}
            >
              Atualizar planilha
            </Button>,
          ]}
        >
          <SearchBar
            placeholder="Pesquisar"
            initialValue={search}
            onSearch={(value) => handleSearch({ ...filters, search: value }, { limit: initialLimit, offset })}
            activeFiltersProps={{ filtros, onSearch: (filters) => handleSearch(filters, { limit: initialLimit, offset }), filters }}
          />
          <Column gap='28px'>
            <Row align='center' gap='8px'>
              <Switch 
                onChange={
                  (checked) => handleSearch(
                    checked ? { ...filters, delinquencyStatus: 'delinquent' } : { ...filters }, { limit: initialLimit, offset }, true
                  )
                }
              />
              <SwitchLabel>Mostrar apenas inadimplentes</SwitchLabel>
            </Row>
            <Grid gap='12px' numberOfColumns={2}>
              {loadingDelinquencyIndicators ? (
                <Skeleton height='150px' flex={1}/>
              ) : (
                <IndicatorCard>
                  <Column gap='12px' flex={1}>
                    <Row justify='space-between' align='center'>
                      <Text size='18px' weight='400'>
                        <Text size='24px' weight='700' color={delinquencyIndicators.undeliquentCompaniesPercent >= 50 ? 'var(--success-color)' : 'var(--danger-color)'}>
                          {String(delinquencyIndicators.undeliquentCompaniesPercent?.toFixed(2))?.replace('.', ',')}%
                        </Text>
                        &nbsp;
                        d{companyNomenclature.artigo}s {companyNomenclature.plural.toLowerCase()} estão adimplentes
                      </Text>
                      <Text size='18px' weight='400' lowOpacity>
                        <Text size='18px' weight='700'>{delinquencyIndicators.numberOfUndelinquentCompanies}</Text>
                          &nbsp;
                          de
                          &nbsp;
                        <Text size='18px' weight='700'>{delinquencyIndicators.numberOfTotalCompanies}</Text>
                          &nbsp;
                        {companyNomenclature.plural.toLowerCase()}
                      </Text>
                    </Row>
                    <Progress.Line 
                      percent={delinquencyIndicators.undeliquentCompaniesPercent} 
                      status={delinquencyIndicators.undeliquentCompaniesPercent >= 50 ? 'success' : 'danger'}
                    />
                  </Column>
                </IndicatorCard>
              )}
              {loadingDelinquencyTotals ? (
                <Skeleton height='150px' flex={1}/>
              ) : (
                <IndicatorCard>
                  <Column gap='12px' flex={1}>
                    <Row justify='space-between' align='center'>
                      <Text size='20px' weight='600'>
                        {delinquencyTotals.totalDelinquentCompanies}
                        &nbsp;
                        {
                          delinquencyTotals.totalDelinquentCompanies !== 1 ? 
                            companyNomenclature.plural.toLowerCase() : 
                            companyNomenclature.nomenclatura.toLowerCase()
                        } inadimplente{delinquencyTotals.totalDelinquentCompanies !== 1 ? 's' : ''}
                      </Text>
                      <Text size='16px' weight='400' lowOpacity>
                        {delinquencyTotals.totalCompaniesDelinquencyTitles}
                        &nbsp;
                        título{delinquencyTotals.totalCompaniesDelinquencyTitles !== 1 ? 's' : ''} vencido{delinquencyTotals.totalCompaniesDelinquencyTitles !== 1 ? 's' : ''}
                      </Text>
                    </Row>
                    <Text size='28px' weight='700' color='var(--danger-color)'>
                      {formatCurrency(delinquencyTotals.totalCompaniesDelinquency)}
                    </Text>
                  </Column>
                </IndicatorCard>
              )}
            </Grid>
            <Column gap='12px'>
              <Table
                columns={columns}
                dataSource={delinquencyList}
                loading={loadingDelinquencyList && !loadingMore}
                hidePagination
                rowKey='id'
                onRow={(record) => {
                  return {
                    onClick: () => history.push(`/unidades/${record.id}`),
                  };
                }}
                onChange={
                  (pagination, filters, { field, order }) => handleSearch(
                    { 
                      ...filtros, 
                    }, 
                    { 
                      limit: initialLimit, 
                      offset, 
                      orderBy: order ? {
                        field: getOrderByFieldFromColumnIndex(field),
                        order: order.replace('end', '')
                      } : false
                    }
                  )
                }
              />
              {(!loadingDelinquencyList || loadingMore) && limit < delinquencyListTotal && (
                <Column>
                  <LoadMoreButton 
                    type='secondary' 
                    icon={<Icon.ReloadOutlined />} 
                    loading={loadingMore}
                    onClick={() => handleLoadMoreClick()} 
                  >
                  Carregar mais
                  </LoadMoreButton>
                </Column>
              )}
            </Column>
          </Column>
        </PageHeader>

        {filtersVisible && (
          <Filter
            visible={filtersVisible}
            onClose={() => setFiltersVisible(false)}
            filtros={filtros}
            onSearch={(filters) => handleSearch(filters, { limit: initialLimit, offset })}
            filters={filters}
          />
        )}
      </Body>
    </App>
  );
}