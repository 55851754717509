import styled from 'styled-components';
import { Text, Form } from '@components-teammove';

export const RiskTitle = styled(Text)`
    font-size: 20px;
    font-weight: 700;
    color: ${({ theme }) => theme.textColor};
`;

export const FormItemSmall = styled(Form.Item)`
    max-width: ${({ colorField }) => colorField ? '60px' : '100px'} !important;
`;

export const ContainerConfiguration = styled.div`
    padding: 20px;
`;