import { List, Map } from 'immutable';
import { call, put, takeLatest } from 'redux-saga/effects';
import { Notification } from '@utils';
import { 
  getPendingGeolocationCompanies as getPendingGeolocationCompaniesSdk,
  updatePendingGeolocationCompanies as updatePendingGeolocationCompaniesSdk,
} from '../../sdk/PendingGeolocationCompanies/index.ts';

//Action Types
export const Types = {
  GET_COMPANIES: 'CONFIGURACOES_UNIDADES_GEOLOCALIZACAO_PENDENTE/GET_COMPANIES',
  GET_COMPANIES_SUCCESS: 'CONFIGURACOES_UNIDADES_GEOLOCALIZACAO_PENDENTE/GET_COMPANIES_SUCCESS',
  GET_COMPANIES_ERROR: 'CONFIGURACOES_UNIDADES_GEOLOCALIZACAO_PENDENTE/GET_COMPANIES_ERROR',

  UPDATE_COMPANIES: 'CONFIGURACOES_UNIDADES_GEOLOCALIZACAO_PENDENTE/UPDATE_COMPANIES',
  UPDATE_COMPANIES_SUCCESS: 'CONFIGURACOES_UNIDADES_GEOLOCALIZACAO_PENDENTE/UPDATE_COMPANIES_SUCCESS',
  UPDATE_COMPANIES_ERROR: 'CONFIGURACOES_UNIDADES_GEOLOCALIZACAO_PENDENTE/UPDATE_COMPANIES_ERROR',

  RESET: 'CONFIGURACOES_UNIDADES_GEOLOCALIZACAO_PENDENTE/RESET',
  RESET_SUCCESS: 'CONFIGURACOES_UNIDADES_GEOLOCALIZACAO_PENDENTE/RESET_SUCCESS',
};

//Action Creators
export const getPendingGeolocationCompanies = () => ({ type: Types.GET_COMPANIES });
export const getPendingGeolocationCompaniesSuccess = (response) => ({ type: Types.GET_COMPANIES_SUCCESS, response });
export const getPendingGeolocationCompaniesError = (error) => ({ type: Types.GET_COMPANIES_ERROR, error });

export const updatePendingGeolocationCompanies = (data) => ({ type: Types.UPDATE_COMPANIES, data });
export const updatePendingGeolocationCompaniesSuccess = (response) => ({ type: Types.UPDATE_COMPANIES_SUCCESS, response });
export const updatePendingGeolocationCompaniesError = (error) => ({ type: Types.UPDATE_COMPANIES_ERROR, error });

export const reset = () => ({ type: Types.RESET });
export const resetSuccess = () => ({ type: Types.RESET_SUCCESS });

function* fetchGetPendingGeolocationCompanies() {
  try {
    const response = yield call(getPendingGeolocationCompaniesSdk);
    yield put(getPendingGeolocationCompaniesSuccess(response));
  } catch (err) {
    Notification.error(err);
    yield put(getPendingGeolocationCompaniesError(err));
  }
}

function* fetchUpdatePendingGeolocationCompanies(action) {
  try {
    const { data } = action;
    const response = yield call(updatePendingGeolocationCompaniesSdk, data);
    yield put(updatePendingGeolocationCompaniesSuccess(response));
    yield put(getPendingGeolocationCompanies());
    Notification.success('Unidades atualizadas com sucesso!');
  } catch (err) {
    Notification.error(err);
    yield put(updatePendingGeolocationCompaniesError(err));
  }
}

export const saga = [
  takeLatest(Types.GET_COMPANIES, fetchGetPendingGeolocationCompanies),
  takeLatest(Types.UPDATE_COMPANIES, fetchUpdatePendingGeolocationCompanies),
];

// Reducer
const initialState = Map({
  pendingGeolocationCompanies: List(),
  totalPendingGeolocationCompanies: 0,
  pendingGeolocationCompaniesMessage: '',
  loadingPendingGeolocationCompanies: false,
  successPendingGeolocationCompanies: false,
  errorPendingGeolocationCompanies: false,

  loadingPendingGeolocationCompaniesSave: false,
  successPendingGeolocationCompaniesSave: false,
  errorPendingGeolocationCompaniesSave: false,
});

const handlependingGeolocationCompanies = (state, action) => {
  return state.set('loadingPendingGeolocationCompanies', true).set('successPendingGeolocationCompanies', false).set('errorPendingGeolocationCompanies', false);
};

const handlependingGeolocationCompaniesSuccess = (state, action) => {
  const { response } = action;
  return state.set('pendingGeolocationCompanies', response.companies)
    .set('totalPendingGeolocationCompanies', response.total)
    .set('pendingGeolocationCompaniesMessage', response.message)
    .set('loadingPendingGeolocationCompanies', false)
    .set('successPendingGeolocationCompanies', true)
    .set('errorPendingGeolocationCompanies', false);
};

const handlependingGeolocationCompaniesError = (state, action) => {
  return state.set('loadingPendingGeolocationCompanies', false).set('successPendingGeolocationCompanies', false).set('errorPendingGeolocationCompanies', action.error);
};

const handlependingGeolocationCompaniesSave = (state, action) => {
  return state.set('loadingPendingGeolocationCompaniesSave', true).set('successPendingGeolocationCompaniesSave', false).set('errorPendingGeolocationCompaniesSave', false);
};

const handlependingGeolocationCompaniesSaveSuccess = (state, action) => {
  return state.set('loadingPendingGeolocationCompaniesSave', false).set('successPendingGeolocationCompaniesSave', true).set('errorPendingGeolocationCompaniesSave', false);
};

const handlependingGeolocationCompaniesSaveError = (state, action) => {
  return state.set('loadingPendingGeolocationCompaniesSave', false).set('successPendingGeolocationCompaniesSave', false).set('errorPendingGeolocationCompaniesSave', action.error);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.GET_COMPANIES: return handlependingGeolocationCompanies(state);
    case Types.GET_COMPANIES_SUCCESS: return handlependingGeolocationCompaniesSuccess(state, action);
    case Types.GET_COMPANIES_ERROR: return handlependingGeolocationCompaniesError(state, action);

    case Types.UPDATE_COMPANIES: return handlependingGeolocationCompaniesSave(state);
    case Types.UPDATE_COMPANIES_SUCCESS: return handlependingGeolocationCompaniesSaveSuccess(state, action);
    case Types.UPDATE_COMPANIES_ERROR: return handlependingGeolocationCompaniesSaveError(state, action);

    case Types.RESET_ALL_VALUES: return initialState;
    case Types.RESET_SUCCESS: return state.set('successPendingGeolocationCompaniesSave', false).set('successPendingGeolocationCompanies', false);
    
    default: return state;
  }
}