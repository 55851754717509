import styled from 'styled-components';

export const NativeInputStyled = styled.textarea`
  flex: 1;
  border: none !important;
  outline: none;
  color: white;
  background-color: ${({ theme }) => theme.darkSecondaryColor} !important;
  resize: none;
  min-height: 24px;
  max-height: 100px;
  overflow-y: auto;
`;
