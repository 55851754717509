import React, { useEffect } from 'react';
import { Column, Row, Tooltip, Text, Button, Icon, View, Dropdown, Menu } from '@components-teammove';
import { DetalheText, FillView, IconWarning, MainTicket, RowSpaceBetween, StyleModal, TableStyle, TextStyle } from './styles';
import PrazoStatusItem from './PrazoStatusItem';
import StatusItem from './StatusItem';
import { STATUS } from './rules';
import { STATUS as STATUS_ETAPAS } from '@containers/Chamados/rules';
import { changeVisibleBriefingReplyEtapas, changeStatus, getChamado } from '@ducks/chamadosHistorico';
import { useDispatch, useSelector } from 'react-redux';
import { InfoUsuario, downloadBlobAsFile } from '@utils';
import { getPrintoutOfSteps, resetBlob, getSteps } from '@ducks/chamadosEtapas';
import { ContainerButton, HoverableContainer, MenuButton } from '../../../../List/styles';

export default function ModalViewSteps({ mainTicket, steps, loadingSteps, visible, setVisibleViewStep, onCancel, setCurrentStep, setMainTicket }) {
  const dispatch = useDispatch();

  const successFinishBriefingReply = useSelector(({ chamadosBriefingReply }) => chamadosBriefingReply.get('successFinishBriefingReply'));

  const successBlob = useSelector(({ chamadosEtapas }) => chamadosEtapas.get('successBlob'));
  const loadingBlob = useSelector(({ chamadosEtapas }) => chamadosEtapas.get('loadingBlob'));
  const blob = useSelector(({ chamadosEtapas }) => chamadosEtapas.get('blob'));
  const usuario = InfoUsuario.get();
  const permiteAtendimento = InfoUsuario.perm('chamadosAtendimento', usuario);
  const administrador = InfoUsuario.perm('chamadosAdministrador', usuario);

  const successStatus = useSelector(({ chamadosHistorico }) => chamadosHistorico.get('successStatus'));
  const successChamado = useSelector(({ chamadosHistorico }) => chamadosHistorico.get('success'));
  const chamado = useSelector(({ chamadosHistorico }) => chamadosHistorico.get('chamado'));

  const handleViewTicket = (step) => {
    if (step.status === STATUS.AGUARDANDO_BRIEFING && step.idUsuarioCriacao === InfoUsuario.get('cdUsuario')) {
      setCurrentStep(step);
      setVisibleViewStep(false);
      dispatch(changeVisibleBriefingReplyEtapas(true));
    } else {
      window.open(`/chamados/tickets/${step.id}`);
    }
  };

  useEffect(() => {
    if (mainTicket?.id && visible) dispatch(getPrintoutOfSteps(mainTicket.id));

    return () => dispatch(resetBlob());

  }, [mainTicket, visible]);

  const handlePrinting = () => {
    if (successBlob) {
      downloadBlobAsFile(blob, `${mainTicket.protocolo}.pdf`);
    } else {
      dispatch(getPrintoutOfSteps(mainTicket.id));
    }
  };

  useEffect(() => {
    dispatch(changeVisibleBriefingReplyEtapas(false));
  }, [successFinishBriefingReply]);

  useEffect(() => {
    if(successStatus) {
      dispatch(getChamado(mainTicket.id));
      dispatch(getSteps(mainTicket.id));
    }
  }, [successStatus]);

  useEffect(() => {
    if(!successChamado) return;
    setMainTicket(chamado);
  }, [successChamado]);

  const handleCancelarAtendimento = (step) => {
    dispatch(changeStatus(step.id, STATUS_ETAPAS.CANCELADO));    
  };

  const handleReabrirAtendimento = (step) => {
    dispatch(changeStatus(step.id, STATUS_ETAPAS.REABERTO));    
  };

  const handleShowOptions = (step) => {
    return permiteAtendimento && (![STATUS_ETAPAS.CONCLUIDO, STATUS_ETAPAS.FECHADO].includes(step.status) || handleShowOptionReopen(step));
  };

  const handleShowOptionCancel = (step) => {
    return permiteAtendimento && (step.status !== STATUS_ETAPAS.FECHADO && step.status !== STATUS_ETAPAS.CANCELADO) && administrador;
  };

  const handleShowOptionReopen = (step) => {
    return permiteAtendimento && (step.status === STATUS_ETAPAS.FECHADO || step.status === STATUS_ETAPAS.AGUARDANDO_AVALIACAO || step.status === STATUS_ETAPAS.CANCELADO);
  };

  const columns = [
    {
      title: 'Etapas',
      dataIndex: 'step',
      render: (text, step) => (
        <Column>
          <TextStyle font='14px' weight='400' $opacity>{step.protocolo}</TextStyle>
          <Tooltip title={step.assunto}>
            <TextStyle maxWidth='320px' font='18px' weight='700'>{step.assunto}</TextStyle>
          </Tooltip>
          <TextStyle font='12px' weight='400' $opacity>{step.nmCategoriaCompleto}</TextStyle>
        </Column>
      )
    },
    {
      title: 'Prazo',
      dataIndex: 'prazo',
      render: (text, step) => (
        <FillView>
          {!step.aguardandoResposta && <PrazoStatusItem chamado={step} />}
          {(step.nmUsuarioAtendimento) && <DetalheText>Resp:{step?.nmUsuarioAtendimento ? step?.nmUsuarioAtendimento : <IconWarning />}</DetalheText>}
        </FillView>
      )
    },
    {
      title: 'Status',
      dataIndex: 'statusDs',
      width: '165px',
      render: (text, step) => {
        return (
          <RowSpaceBetween>
            <StatusItem texto={text} chamado={step} />
            {handleShowOptions(step) && (
              <View
                onClick={(e) => (e.stopPropagation())}
              >
                <Dropdown
                  key="more"
                  overlay={(
                    <Menu>      
                      {handleShowOptionCancel(step) &&
                          <ContainerButton>
                            <HoverableContainer>
                              <MenuButton onClick={(e) => {e.stopPropagation(); handleCancelarAtendimento(step); }} type="icon" icon={<Icon.Feather type='trash-2' size='18px' />}>
                                Cancelar ticket
                              </MenuButton>      
                            </HoverableContainer>  
                          </ContainerButton>           
                      }
                      {handleShowOptionReopen(step) &&
                          <Menu.Item
                            onClick={(e) => { e.domEvent.stopPropagation(); handleReabrirAtendimento(step); }} 
                          >
                            <MenuButton type="icon" icon={<Icon.Font type="play" />}>
                              Reabrir atendimento
                            </MenuButton>                    
                          </Menu.Item>}
                    </Menu> 
                  )}
                >
                  <Button type="icon">
                    <Icon.MDI type="dots-vertical" />
                  </Button>
                </Dropdown>
              </View>
            )}
          </RowSpaceBetween>
        );
      }
    },
  ];

  return (
    <StyleModal
      visible={visible}
      onCancel={onCancel}
      width={885}
      title='Visualização de etapas'
      cancelText='Fechar'
      onOk={handlePrinting}
      okButtonProps={{ loading: loadingBlob }}
      okText='Imprimir'
    >
      <Column gap='32px'>
        <Column gap='12px'>
          <Row padding='0 0 0 24px'>
            <Text size='16px' weight='700'>Ticket Origem</Text>
          </Row>
          <MainTicket>
            <Column width='40%'>
              <TextStyle font='14px' weight='400' $opacity>{mainTicket?.protocolo}</TextStyle>
              <Tooltip title={mainTicket.assunto}>
                <TextStyle maxWidth='320px' font='18px' weight='700'>{mainTicket?.assunto}</TextStyle>
              </Tooltip>
              <TextStyle font='12px' weight='400' $opacity>{mainTicket?.nmCategoriaCompleto}</TextStyle>
            </Column>
            <Column justify='center'>
              <TextStyle font='18px' weight='400'>{mainTicket?.nmUnidade}</TextStyle>
            </Column>
            <Row gap='48px' align='center'>
              <Column>
                <FillView>
                  {!mainTicket.aguardandoResposta && <PrazoStatusItem chamado={mainTicket} />}
                  {(mainTicket.nmUsuarioAtendimento) && <TextStyle font='12px' weight='400' $opacity>Resp:{mainTicket?.nmUsuarioAtendimento ? mainTicket?.nmUsuarioAtendimento : <IconWarning />}</TextStyle>}
                </FillView>
              </Column>
              <RowSpaceBetween>
                <StatusItem texto={mainTicket.statusDs} chamado={mainTicket} />
              </RowSpaceBetween>
            </Row>
          </MainTicket>
        </Column>
        <TableStyle
          columns={columns}
          dataSource={steps}
          loading={loadingSteps}
          onRow={(step) => ({ onClick: () => handleViewTicket(step) })}
          hidePagination
        />
      </Column>
    </StyleModal>
  );
}
