import React, { useEffect, useState } from 'react';
import { Column, Text, Modal, Row, Button, Table } from '@components-teammove';
import { InfoUsuario, TelasNomenclaturas, formatDate } from '@utils';
import { authorization } from '@sdk/api';
import { ComponentEmptyCover, UploadEdge, UploadIcon, UploadText } from './styles';
import { CompanyImportModals } from './CompanyImportModals';
import { uploadExcel, resetAllValues, getDowloadExcelTemplate, resetSuccessDownloadTemplate } from '@ducks/companyImport';
import { useDispatch, useSelector } from 'react-redux';
import { getPendingGeolocationCompanies, updatePendingGeolocationCompanies } from '../../../../store/ducks/pendingGeolocationCompanies';

const { REACT_APP_API } = window.env;

export const CompanyImport = () => {
  const dispatch = useDispatch();

  const companyNomenclature = TelasNomenclaturas.getNomenclatures('UNIDADES');

  const templateUrl = useSelector(({ companyImport }) => companyImport.get('templateUrl'));
  const loadingTemplateUrl = useSelector(({ companyImport }) => companyImport.get('loadingTemplateUrl'));
  const successTemplateUrl = useSelector(({ companyImport }) => companyImport.get('successTemplateUrl'));

  const pendingGeolocationCompanies = useSelector(({ pendingGeolocationCompanies }) => pendingGeolocationCompanies.get('pendingGeolocationCompanies'));
  const totalPendingGeolocationCompanies = useSelector(({ pendingGeolocationCompanies }) => pendingGeolocationCompanies.get('totalPendingGeolocationCompanies'));
  const pendingGeolocationCompaniesMessage = useSelector(({ pendingGeolocationCompanies }) => pendingGeolocationCompanies.get('pendingGeolocationCompaniesMessage'));

  const loadingPendingGeolocationCompaniesUpdate = useSelector(({ pendingGeolocationCompanies }) => pendingGeolocationCompanies.get('loadingPendingGeolocationCompaniesSave'));

  const [ visibleModals, setVisibleModals ] = useState(false);
  const [ excelUrl, setExcelUrl ] = useState();
  const [ visibleDowloadTemplate, setVisibleDowloadTemplate ] = useState(false);

  const handleChangeAttachment = (info) => {
    setVisibleModals(true);
    if (info.file.status === 'done') {
      setExcelUrl(info.file.response);
    }
  };

  useEffect(() => {
    dispatch(getPendingGeolocationCompanies());
  }, []);

  useEffect(() => {
    if(excelUrl) {
      dispatch(uploadExcel(excelUrl));
    }
  },[excelUrl]);

  useEffect(() => {
    if(successTemplateUrl) {
      const link = document.createElement('a');
      link.href = templateUrl;
      link.setAttribute('download', 'template.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
    }

    return () => {
      dispatch(resetSuccessDownloadTemplate()); 
    };
  }, [successTemplateUrl]);

  const handleUpdateGeolocationClick = () => {
    dispatch(updatePendingGeolocationCompanies());
  };

  return(
    <Column gap='40px'>
      <Column gap='20px'>
        <Text size='28px' weight='700'>Upload da planilha</Text>
        <Column gap='6px'>
          <Text size='16px' weight='400'>Esperamos que sua planilha siga este padrão:</Text>
          <Button key="1" type="secondary" size='small' loading={loadingTemplateUrl} context='header' onClick={() => setVisibleDowloadTemplate(true)}>Baixar template</Button>
        </Column>
      </Column>
     
      <UploadEdge
        name="file"
        showUploadList={false}
        headers={authorization()}
        folder={`importacao/company/${InfoUsuario.get('cdCliente')}`}
        URI={REACT_APP_API}
        // onDownload={(file) => download(file.response)}
        accept=".xls, .xlsx"
        onChange={handleChangeAttachment}
      >
        <ComponentEmptyCover>
          <UploadIcon type='upload' />
          <UploadText>Clique ou arraste para anexar</UploadText>
        </ComponentEmptyCover>
      </UploadEdge>

      <Column gap='12px'>
        {pendingGeolocationCompanies && pendingGeolocationCompanies.length > 0 && (
          <>
            <Column>
              <Text size='16px' weight='700'>
              Há {totalPendingGeolocationCompanies} {(totalPendingGeolocationCompanies > 1 ? companyNomenclature.plural : companyNomenclature.nomenclatura).toLowerCase()} com geolocalização pendente de atualização
              </Text>
              {pendingGeolocationCompaniesMessage && (
                <Text size='14px' weight='400' lowOpacity>{pendingGeolocationCompaniesMessage}</Text>
              )}
            </Column>
            <Button
              type='primary'
              size='small'
              onClick={handleUpdateGeolocationClick}
              loading={loadingPendingGeolocationCompaniesUpdate}
            >
            Atualizar geolocalização
            </Button>
        
            <Table
              columns={[
                {
                  title: companyNomenclature.nomenclatura,
                  dataIndex: 'name',
                  key: 'name',
                  render: (name, { shortName, city, state }) => (
                    <Column>
                      <Text size='12px' lowOpacity weight='400'>{shortName}</Text>
                      <Text size='14px' weight='700'>{name}</Text>
                      <Text size='12px' lowOpacity weight='400'>{[city, state].filter(Boolean).join(' - ')}</Text>
                    </Column>
                  ),
                },
                {
                  title: 'Pendente desde',
                  dataIndex: 'pendingSince',
                  key: 'pendingSince',
                  render: (text) => formatDate(text),
                },
                {
                  title: 'Número de tentativas',
                  dataIndex: 'numberOfTries',
                  key: 'numberOfTries',
                },
              ]}
              dataSource={pendingGeolocationCompanies}
              pagination={{
                pageSize: 5,
              }}
            />
          </>
        )}
      </Column>

      {visibleModals && 
        <CompanyImportModals 
          setVisibleModals={setVisibleModals}
          onClose={() => {setVisibleModals(false); dispatch(resetAllValues());}}
        />
      }
      {visibleDowloadTemplate && 
        <Modal 
          visible
          onCancel={() => setVisibleDowloadTemplate(false)}
          title='Escolha como você quer exportar o template da planilha!'
          footer={
            <Row>
              <Button key={1} context='header' type="secondary" fillWidth  onClick={() => {setVisibleDowloadTemplate(false);dispatch(getDowloadExcelTemplate(true));}}>Com dados</Button>
              <Button key={2} context='header' type="primary" fillWidth  onClick={()=>  {setVisibleDowloadTemplate(false);dispatch(getDowloadExcelTemplate(false));}}>Sem dados</Button>
            </Row>
          }
        />
      }
    </Column>
  );
};

