import styled from 'styled-components';
import { Upload } from 'antd';
import Text from '../Text';
import Icon from '../Icon';

export const StyledUpload = styled(Upload)`
  * {
    color: ${(props) => props.theme.backgroundColor};
  }
  
  ${({ fill }) => fill && `
    width: 100%;
    display: inline-block;

    .ant-upload {
      width: 100%;
      display: inline-block;
    }
  `}

  .ant-upload-list {
    .ant-upload-list-item-info{
      :hover {
        background-color: transparent;
      } 
      > span {
        position: relative;
        display: flex;
        gap: 8px;
        align-items: center;

        .ant-upload-list-item-thumbnail {
          position: static;
        }
        .ant-upload-list-item-name {
          flex: 1;
          padding: 0;
          margin: 0;
          color: ${({ theme }) => theme.textColor};
        }
        .ant-upload-list-item-card-actions {
          position: static;
          height: fit-content;
        }
      }
    }
  }
`;

export const UploadDragger = styled(Upload.Dragger)`
  &.ant-upload.ant-upload-drag {
    background-color: ${(props) => props.theme.darkPrimaryColor} !important;
    border: 1px dashed ${(props) => props.theme.darkHighlightColor};

    .ant-upload-drag-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    :hover {
      border-color: ${(props) => props.theme.actionColor};
    }
  }

  +.ant-upload-list {
    .ant-upload-list-item-info{
      :hover {
        background-color: transparent;
      } 
      > span {
        position: relative;
        display: flex;
        gap: 8px;
        align-items: center;

        .ant-upload-list-item-thumbnail {
          position: static;
        }
        .ant-upload-list-item-name {
          flex: 1;
          padding: 0;
          margin: 0;
          color: ${({ theme }) => theme.textColor};
        }
        .ant-upload-list-item-card-actions {
          position: static;
          height: fit-content;
        }
      }
    }
  }
`;

export const CloseIcon = styled(Icon.CloseOutlined)`
  &.anticon.anticon-close {
    position: static;
  }
  svg {
    width: 24px;
    height: 24px;
  }
`;

export const IconStyled = styled(Icon.Feather)`
   color: ${({ theme }) => theme.actionColor};

  * {
   color: ${({ theme }) => theme.actionColor};
  }
`;

export const UploadText = styled(Text)`
  &.ant-typography {
    color: ${({ theme }) => theme.textColor};
    opacity: 50%;
  }
`;