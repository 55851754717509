import { Table } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  tbody {
    tr.updated-row {
      &.ant-table-row {
        > td {
          background-color: #F3FFF2 !important;
        }
      }
      &.ant-table-row:hover {
        > td {
          background-color: #e8ffe6 !important; /* darken 5% */
        }
      }
    }

    tr.disabled-row {
      opacity: .5;
    }
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const LoadingRow = styled.div`
  height: 93px;
  border-radius: 12px;
`;

export const LoadingHeaderRow = styled(LoadingRow)`
  height: 43px;
`;

export const LoadingCell = styled.div`
  height: 93px;
  width: ${({ width }) => width || '300px'};
  border-radius: 12px;
`;

export const StyledTable = styled(Table)`
  &.ant-table {
    background-color: transparent;
    border-radius: 12px;
    
    .ant-table-content, .ant-table-container {
      border-radius: 0 0 12px 12px;

      thead {

        > tr {

          > th {
            padding: 12px 3px 12px 14px;
            background-color: ${(props) => props.theme.darkPrimaryColor};
            font-size: 16px;
            font-weight: 600;
            color: ${(props) => props.theme.textColor};
            border-bottom: 0;

            :first-child {
              border-top-left-radius: 12px;
              border-bottom-left-radius: 12px;           
            }

            :not(:last-child):after {
              content: '';
              display: block;
              position: absolute;
              bottom: 50%;
              top: 50%;
              right: 0;
              height: 35%;
              width: 1px;
              transform: translate(-50%, -50%);
              background: ${(props) => props.theme.textColor};
              opacity: .5;
              transition: all .2s;
            }

            :last-child {
              border-right: none;
              border-top-right-radius: 12px;
              border-bottom-right-radius: 12px;
              padding: 12px 14px;
            }

            :hover {
              background-color: ${(props) => props.theme.darkHighlightColor};

              &.ant-table-column-has-sorters .ant-table-column-sorters .ant-table-column-sorter.ant-table-column-sorter-full .ant-table-column-sorter-inner {
                .ant-table-column-sorter-up, .ant-table-column-sorter-down {
                  visibility: visible !important;
                }
              }
            }

            &.ant-table-column-has-sorters {
              .ant-table-column-sorters {
                width: 100%;
              }

              :not(.ant-table-column-sort) {
                .ant-table-column-sorter-up, .ant-table-column-sorter-down {
                  visibility: hidden;
                }
              }
            }
          }
        }
      }

      tbody {
        > tr {
          height: fit-content;
          cursor: pointer;
          :not(:last-child) td {
            ${({ spaced, theme }) => !spaced && `
              border-bottom: 2px solid ${theme.darkPrimaryColor};
            `}
          }

          .ant-table-cell-fix-left, .ant-table-cell-fix-right {
            background-color: ${(props) => props.theme.darkPrimaryColor};
          }

          td {
            height: 100%;
            padding: 12px 14px;
            font-size: 14px;
            font-weight: 400;
            background-color: ${(props) => props.theme.darkSecondaryColor};
            color: ${(props) => props.theme.textColor};
            border-bottom: 0;
            vertical-align: middle;
            .ant-typography {
              color: ${(props) => props.theme.textColor};
            }
          }

          :hover {
            td {
              background-color: ${(props) => props.theme.darkPrimaryColor};
              
              .ant-btn.ant-btn-secondary {
                background-color: ${(props) => props.theme.darkSecondaryColor};
              }
            }
          }

          :first-child {
            td:first-child {
              border-radius: 12px 0 0 ${({ spaced })=> spaced ? '12px' : '0'};
            }
            td:last-child {
              border-radius: 0 12px 0 0;
            }
            td:first-child:last-child {
              border-radius: 12px 12px 0 0;
            }
          }
        }
        
        :before {
          content: "-";
          display: block;
          line-height: 8px;
          color: transparent;
        }
      }

      tbody > tr.ant-table-placeholder:hover > td {
        background-color: ${(props) => props.theme.darkPrimaryColor};
      }
    }

    .ant-empty {
      .ant-empty-description {
        color: ${(props) => props.theme.textColor};
      }
    }

    .ant-table-footer {
      background-color: ${(props) => props.theme.darkSecondaryColor};
      color: ${(props) => props.theme.textColor};
    }

    ${({ spaced, theme }) => spaced && `
      background-color: transparent;

      table {
        border-spacing: 0 12px;

        thead tr {
          border: 1px solid transparent;

          th:first-child {
            border-radius: 12px 0 0 0;
          }
          th:last-child {
            border-radius: 0 12px 0 0;
          }
        }
        
        tbody tr.ant-table-row {
          background-color: transparent;
          :hover {
            background-color: transparent;

            td {
              background-color: ${theme.darkPrimaryColor};
            }
          }

          td:first-child {
            border-radius: 12px 0 0 12px;
          }
          td:last-child {
            border-radius: 0 12px 12px 0;
          }
          td {
            background-color: ${theme.darkSecondaryColor};
            padding: 0;
          }
        }
      }
    `}

    &+.ant-pagination {
      .ant-pagination-prev, .ant-pagination-next, .ant-pagination-item, .ant-pagination-options {
        a, span {
          color: ${({ theme }) => theme.textColor};
        }
      }

      .ant-pagination-item-active {
        background-color: transparent;
        border-color: ${({ theme }) => theme.actionColor};

        a {
          color: ${({ theme }) => theme.actionColor};
        }
      }

      .ant-pagination-options {
        .ant-select-selector {
          background-color: transparent;
          border: none;
          color: ${({ theme }) => theme.textColor};
        }

        .ant-select-dropdown {
          background-color: ${({ theme }) => theme.darkSecondaryColor};
          
          .ant-select-item {
            color: ${({ theme }) => theme.textColor};

            &.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
              background-color: ${({ theme }) => theme.actionColor};
            }
          }
        }

        .ant-pagination-options-quick-jumper {
          color: ${({ theme }) => theme.textColor};

          input {
            background-color: ${({ theme }) => theme.darkSecondaryColor};
            border-color: ${({ theme }) => theme.darkPrimaryColor};
            color: ${({ theme }) => theme.textColor};
          }
        }
      }
    }
  }
`;