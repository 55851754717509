import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Checkbox, Input, InputNumber, Picker, Row, Column, Skeleton } from '@components-teammove';
import { getChecklistTemplateConfiguration, resetConfiguration } from '@ducks/checklistTemplates';
import { ContainerConfiguration, FormItemSmall, RiskTitle } from './styles';
import { mapperToForm } from './rules';

const ConfiguracaoFaixaPontuacaoChecklist = ({ form }) => {
  const dispatch = useDispatch();
  const [showWeight, setShowWeight] = useState(false);

  const checklistTemplateConfiguration = useSelector(({ checklistTemplates }) => checklistTemplates.get('checklistTemplateConfiguration'));
  const loadingChecklistTemplateConfiguration = useSelector(({ checklistTemplates }) => checklistTemplates.get('loadingChecklistTemplateConfiguration'));
  const successChecklistTemplateConfiguration = useSelector(({ checklistTemplates }) => checklistTemplates.get('successChecklistTemplateConfiguration'));

  useEffect(() => {
    dispatch(getChecklistTemplateConfiguration());
    return () => dispatch(resetConfiguration());
  }, []);

  useEffect(() => {
    if(!successChecklistTemplateConfiguration) return;
    form.setFieldsValue(mapperToForm(checklistTemplateConfiguration));
  }, [successChecklistTemplateConfiguration]);

  const handleValuesChange = (changedValues, allValues) => {
    if(changedValues.risks) {
      const { risks } = changedValues;
      risks.forEach((risk, index) => {
        if (risk.percentage) {
          if (allValues.risks[index + 1]) {
            const updatedRisks = [...allValues.risks];
            updatedRisks[index + 1].previousPercentage = risk.percentage;
            form.resetFields(['risks']);
            form.setFieldsValue({ risks: updatedRisks });
          }
        }
      });
    }
  };

  return (
    <ContainerConfiguration>
      <Form form={form}>
        <Form.Item labelSide='right' label='Mostrar peso ao responder checklist' colon={false} name='faixaPontuacao' valuePropName='checked'>
          <Checkbox onChange={(value) => setShowWeight(value)} checked={showWeight}/>
        </Form.Item>
      </Form>
      <Column gap='20px'>
        <RiskTitle>Mapeamento de risco</RiskTitle>
        {loadingChecklistTemplateConfiguration || !successChecklistTemplateConfiguration ? (
          <Skeleton.Form/>
        ) : (
          <Form form={form} layout='vertical' onValuesChange={handleValuesChange}>
            {checklistTemplateConfiguration?.risks.map((configurationRisk, index) => (
              <Row key={configurationRisk.id} align='center' gap='12px'>
                <Form.Item name={['risks', index, 'id']} hidden/>
                <FormItemSmall name={['risks', index, 'previousPercentage']} label='De'>
                  <InputNumber disabled/>
                </FormItemSmall>
                <FormItemSmall 
                  name={['risks', index, 'percentage']}
                  label='Até'
                  rules={[
                    {
                      validator: (first, value) => {
                        const risks = form.getFieldValue('risks');
                
                        const previousPercentage = risks[index - 1]?.percentage;
                        const nextPercentage = risks[index + 1]?.percentage;
                
                        if (previousPercentage && value < previousPercentage) {
                          return Promise.reject(
                            new Error(
                              `O porcentual ${value} deve ser maior do que ${previousPercentage}.`
                            )
                          );
                        }

                        if (nextPercentage && value > nextPercentage) {
                          return Promise.reject(
                            new Error(
                              `O porcentual ${value} deve ser menor do que ${nextPercentage}.`
                            )
                          );
                        }
                
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <InputNumber disabled={index === checklistTemplateConfiguration.risks.length - 1}/>
                </FormItemSmall>
                <FormItemSmall
                  colorField
                  valuePropName='color'
                  name={['risks', index, 'color']}
                  label='Cor'
                >
                  <Picker
                    size='small'
                  />
                </FormItemSmall>
                <Form.Item name={['risks', index, 'description']} label='Descrição'>
                  <Input/>
                </Form.Item>
              </Row>  
            ))}
          </Form>
        )}
      </Column>
    </ContainerConfiguration>
  );
};

export default ConfiguracaoFaixaPontuacaoChecklist;