// eslint-disable-import/no-unresolved

import React, { useState } from 'react';
import App from '@app';
import { Body, PageHeader, Column, Tabs, Breadcrumb, Button, Form } from '@components-teammove';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Session, Filtros } from '@utils';
import Templates from './Template';
import { FILTERS_KEY, getChecklistTemplates, putChecklistTemplateConfiguration } from '@ducks/checklistTemplates';
import ConfiguracaoFaixaPontuacaoChecklist from './Configuracao';

export default function ConfiguracoesChecklists() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();

  const theme = useSelector(({ app }) => app.get('theme'));
  
  const [currentTab, setCurrentTab] = useState(Session.get('CONFIG_CHECKLISTS_TAB') || 'Templates');

  const [filtros, setFiltros] = useState(Filtros.get(FILTERS_KEY));
  const initialLimit = 50;
  const [limit, setLimit] = useState(initialLimit);
  const [offset] = useState(0); // TODO: use this for pagination
  const [search, setSearch] = useState('');
  const [orderBy, setOrderBy] = useState({ field: 'name', order: 'asc' });
  const [visibleConfigurationChecklistTemplate, setVisibleConfigurationChecklistTemplate] = useState(false);

  const loadingSaveChecklistTemplateConfiguration = useSelector(({ checklistTemplates }) => checklistTemplates.get('loadingSaveChecklistTemplateConfiguration'));
  
  const handleChangeTab = (newTab) => {
    setCurrentTab(newTab);
    Session.set('CONFIG_CHECKLISTS_TAB', newTab);
  };

  const ExtraChecklistTemplate = () => 
    currentTab === 'Templates' ? [
      <Button 
        key="2" 
        type="primary" 
        size='small' 
        context='header' 
        onClick={() => history.push('/configuracoes/checklists/cadastro')}
      >
      Novo
      </Button>,
    ] : [
      <Button 
        key="1" 
        type="primary" 
        size='small' 
        context='header' 
        onClick={handleSaveConfiguration}
        loading={loadingSaveChecklistTemplateConfiguration}
      >
      Salvar
      </Button>,
    ];

  const handleSearch = (filters, loaders) => {
    Filtros.set(FILTERS_KEY, filters);
    setFiltros(filters);
    setLimit(loaders.limit);
    setSearch(filters.search || '');
    setOrderBy(loaders.orderBy);

    dispatch(getChecklistTemplates({ 
      ...filters, 
      ...loaders 
    }));
  };

  const handleSaveConfiguration = () => {
    form.validateFields().then((values) => {
      dispatch(putChecklistTemplateConfiguration(values));
    }).catch(Form.scrollToFirstError);
  };

  return (
    <App bgColor={theme['@global-background-color']}>
      <Body>
        <PageHeader
          title='Checklists'
          extra={<ExtraChecklistTemplate/> }
          breadcrumb={(
            <Breadcrumb
              items={[
                { path: '/', name: 'Home' },
                { path: '/configuracoes', name: 'Configurações' },
                { path: 'configuracoes/checklists', name: 'Configurações de checklists', current: true }
              ]}
            />
          )}
          onBack={() => window.history.back()}
        >
          <Column gap='12px'>
            <Tabs 
              options={[
                { name: 'Templates' },
                { name: 'Faixa de Pontuação' }
              ]} 
              current={currentTab} 
              onChange={handleChangeTab}
            />
            {currentTab === 'Templates' ? (
              <Templates
                filtros={filtros}
                search={search}
                limit={limit}
                offset={offset}
                orderBy={orderBy}
                handleSearch={handleSearch}
              />
            ) : (
              <ConfiguracaoFaixaPontuacaoChecklist
                form={form}
              />
            )}
          </Column>
        </PageHeader>
        {visibleConfigurationChecklistTemplate && (
          <ConfiguracaoFaixaPontuacaoChecklist
            visible={visibleConfigurationChecklistTemplate}
            onCancel={() => setVisibleConfigurationChecklistTemplate(false)}
          />
        )}
      </Body>
    </App>
  );
}
